import { Dispatch, FC, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'assets/svg/close-icon';
import { DemographicsContext } from 'context/DemographicsContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';

import './index.scss';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const createTextColumn = (values: (string | undefined)[]) => {
  return values
    .filter(value => value !== undefined)
    .map((value, index) => {
      return (
        <p key={index} className={'current-settings-modal_value'}>
          {value}
        </p>
      );
    });
};

const CurrentSettingsModal: FC<Props> = ({ setVisible }) => {
  const { numberOfVariations, ratios } = useContext(RenderSettingsContext);
  const { age, gender, ethnicity } = useContext(DemographicsContext);
  const { shot, lighting, cameraType } = useContext(LightingContext);
  const { eyesColor, hairColor, skinTone, appearance, body, makeup, expression, facialHair, shavedHead } =
    useContext(PhysicalDetailsContext);
  const { t } = useTranslation();

  return (
    <div className={`current-settings-modal-_container`}>
      <div className={`current-settings-modal-inner_container`}>
        <div className="log-in-top-bar">
          <p className="sign-in-text">{'Current settings'}</p>
          <button className="first-time-generation__physical-details-close-btn" onClick={() => setVisible(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="current-settings-modal_row">
          <div className={'current-settings-modal_info_column'}>
            <div className={'row_title'}>{'Demographics'}</div>
            <div className="current-settings-modal-values_container">
              {createTextColumn([age?.label, gender?.label, ethnicity?.label])}
            </div>
          </div>
          <div className={'current-settings-modal_info_column'}>
            <div className={'row_title'}>{'Camera, Shot & Lighting'}</div>
            <div className="current-settings-modal-values_container">
              {createTextColumn([shot?.label, lighting?.label, cameraType?.label])}
            </div>
          </div>
          <div className={'current-settings-modal_info_column'}>
            <div className={'row_title'}>{'Physical details'}</div>
            <div className="current-settings-modal-values_container">
              {createTextColumn([
                eyesColor?.label,
                hairColor?.label,
                skinTone?.label,
                appearance?.label,
                body?.label,
                makeup?.label,
                expression?.label,
                facialHair?.label,
                shavedHead?.label,
              ])}
            </div>
          </div>
          <div className={'current-settings-modal_info_column'}>
            <div className={'row_title'}>{'Render settings'}</div>
            <div className="current-settings-modal-values_container">
              {numberOfVariations && (
                <p className={'current-settings-modal_value'}>{numberOfVariations + ' renders'}</p>
              )}
              {ratios && <p className={'current-settings-modal_value'}>{ratios + ' Aspect ratio'}</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentSettingsModal;

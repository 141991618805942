import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { Ratios } from 'constants/images';

type UpdatePreset = {
  updateId: (value: string) => void;
  id: string;
};

export type GeneratedData = {
  id: string;
  images: { id: string; url: string }[];
  status: string;
};

interface Context {
  numberOfVariations: 2 | 4 | undefined;
  setNumberOfVariations: Dispatch<SetStateAction<2 | 4 | undefined>>;
  ratios: Ratios | undefined;
  setRatios: Dispatch<SetStateAction<Ratios | undefined>>;
  isVisited: boolean;
  setIsVisited: Dispatch<SetStateAction<boolean>>;
  resetAllStates: () => void;
  iseRenderSettingsReady: boolean;
}

export const RenderSettingsContext = createContext<Context>({} as Context);

interface Props {
  children: ReactNode;
}

export const RenderSettingsProvider: FC<Props> = ({ children }) => {
  const [numberOfVariations, setNumberOfVariations] = useState<2 | 4 | undefined>();
  const [ratios, setRatios] = useState<Ratios | undefined>();

  const [isVisited, setIsVisited] = useState(false);

  const iseRenderSettingsReady = !!(numberOfVariations && ratios);

  const resetAllStates = () => {
    setRatios(undefined);
    setNumberOfVariations(undefined);
    setIsVisited(false);
  };

  return (
    <RenderSettingsContext.Provider
      value={{
        isVisited,
        setIsVisited,
        resetAllStates,
        numberOfVariations,
        setNumberOfVariations,
        iseRenderSettingsReady,
        ratios,
        setRatios,
      }}
    >
      {children}
    </RenderSettingsContext.Provider>
  );
};

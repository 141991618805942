import { CSSProperties } from 'react';

const GenerateTabIcon = ({
  style,
  color,
  className,
}: {
  className?: string;
  style?: CSSProperties;
  color?: string;
}) => (
  <svg
    width="18"
    height="18"
    style={style}
    viewBox="0 0 18 18"
    className={className}
    display={'flex'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Edit">
      <path
        id="Edit_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.968667 10.9157L10.6592 1.41662C11.906 0.194461 13.9283 0.194461 15.175 1.41662L16.4694 2.6854C17.7162 3.90755 17.7162 5.88985 16.4694 7.112L6.74061 16.6486C6.1843 17.1939 5.43007 17.4999 4.64282 17.4999H0.658541C0.288415 17.4999 -0.00901523 17.201 0.000208899 16.8383L0.100432 12.8975C0.120357 12.1526 0.43127 11.4425 0.968667 10.9157ZM15.5381 3.59831L14.2437 2.32953C13.5113 1.61156 12.323 1.61156 11.5905 2.32953L10.8382 3.06697L14.7862 6.93612L15.5381 6.19909C16.2705 5.48112 16.2705 4.31628 15.5381 3.59831ZM1.89998 11.8287L9.90692 3.97989L13.8549 7.84903L5.8093 15.7357L5.70228 15.8317C5.4067 16.0745 5.03222 16.2088 4.64282 16.2088L1.33345 16.2084L1.41707 12.9305C1.42814 12.5169 1.60126 12.1215 1.89998 11.8287ZM18 16.8545C18 16.498 17.7052 16.2089 17.3415 16.2089H10.471L10.3816 16.2148C10.0602 16.2576 9.81246 16.5277 9.81246 16.8545C9.81246 17.211 10.1073 17.5 10.471 17.5H17.3415L17.4308 17.4941C17.7523 17.4514 18 17.1813 18 16.8545Z"
        fill={color ? color : '#7E7E7E'}
      />
    </g>
  </svg>
);
export default GenerateTabIcon;

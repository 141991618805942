export const ArrowRightIcon = ({ className, onClick }: { className?: string; onClick?: () => void }) => (
  <svg
    width="40"
    className={className}
    onClick={onClick}
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="1" width="39" height="39" rx="19.5" stroke="currentColor" />
    <path
      d="M16.7459 28.2589C16.4478 27.9667 16.4207 27.5095 16.6646 27.1873L16.7459 27.095L23.4734 20.5L16.7459 13.905C16.4478 13.6128 16.4207 13.1556 16.6646 12.8334L16.7459 12.7411C17.044 12.4489 17.5104 12.4223 17.839 12.6614L17.9332 12.7411L25.2541 19.918C25.5522 20.2102 25.5793 20.6675 25.3354 20.9897L25.2541 21.082L17.9332 28.2589C17.6053 28.5804 17.0738 28.5804 16.7459 28.2589Z"
      fill="currentColor"
    />
  </svg>
);

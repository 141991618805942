import { FC } from 'react';
import RSelect, { components, DropdownIndicatorProps } from 'react-select';

import ArrowDownIcon from 'assets/svg/arrow-down-icon';

import './index.scss';

export type RSelectOption = { value: string; label: string; preview?: string; parent?: string };

interface Props {
  options?: RSelectOption[];
  onChange?: (newValue: any) => void;
  value?: RSelectOption;
  className?: string;
  placeholder: string;
  disabled?: boolean;
}

const Select: FC<Props> = ({ options, onChange, value, placeholder, className, disabled }) => {
  const DropdownIndicator: FC<DropdownIndicatorProps> = props => (
    <components.DropdownIndicator {...props}>
      <ArrowDownIcon />
    </components.DropdownIndicator>
  );

  return (
    <RSelect
      className={`react-select-container ${className}`}
      classNamePrefix="react-select"
      isDisabled={disabled}
      placeholder={placeholder}
      maxMenuHeight={210}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      options={options ?? []}
      onChange={onChange}
      value={value ?? ''}
      isSearchable={false}
    />
  );
};

export default Select;

// @ts-ignore-this-file
import { PresetOption } from 'api/models/presets';
import { Ratios } from 'constants/images';

const getInfoRowValuesAsString = (values: (PresetOption | Record<string, string>)[]): string => {
  return values
    .reduce((acc: string, currentValue: PresetOption | Record<string, string>) => {
      const title = 'title' in currentValue ? currentValue.title : Object.values(currentValue)[0];
      return `${acc} ${title !== 'None' ? `${title},` : ''}`;
    }, '')
    .trim()
    .slice(0, -1);
};

export const createTextRow = (title: string, values: PresetOption[] | Record<string, string>[]) => (
  <div className={'info_column'}>
    <div className={'row_title'}>{title}</div>
    <span>{getInfoRowValuesAsString(values)}</span>
  </div>
);

export const getRendersOptions = (
  title: string,
  width: number | undefined,
  height: number | undefined,
  numberOfImages: number | undefined,
) => {
  const renders: string[] = [];
  if (width && height) renders.push(getImageAspectRatio(width, height));
  if (numberOfImages) renders.push(numberOfImages.toString());

  return (
    <div className={'info_column'}>
      <div className={'row_title'}>{title}</div>
      {renders.map(item => (
        <span key={item}>{item}</span>
      ))}
    </div>
  );
};

export const getImageAspectRatio = (width: number, height: number): Ratios => {
  if (width === 688 && height === 1024) {
    return Ratios.PORTRAIT;
  }

  if (width === 1024 && height === 688) {
    return Ratios.ALBUM;
  }

  return Ratios.SQUARE;
};

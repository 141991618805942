import { FC } from 'react';

import { t } from 'i18next';

import './index.scss';

const GenerationGuideBanner: FC = () => (
  <div className="generation-guide-banner">
    <p className="banner-text banner-finish">
      {t('firstTimeGeneration.generationGuideBanner.click')}{' '}
      <span className="banner-text banner-contrast-grey">
        {t('firstTimeGeneration.generationGuideBanner.editSettings')}
      </span>{' '}
      {t('firstTimeGeneration.generationGuideBanner.toMakeFinalChanges')}
    </p>
    <p className="banner-text banner-describe">
      {t('firstTimeGeneration.generationGuideBanner.describeClothingLocation')}{' '}
      <span className="banner-text banner-contrast">{t('firstTimeGeneration.generationGuideBanner.generate')}</span>.
    </p>
  </div>
);

export default GenerationGuideBanner;

import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/AuthContext';

import ButtonsBlock from './components/ButtonsBlock';
import './index.scss';

const Header: FC = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useContext(AuthContext);
  return (
    <div className="header">
      <p className="app-name">{t('global.expressMode')}</p>
      {isSignedIn && <ButtonsBlock />}
    </div>
  );
};

export default Header;

import { FC, HTMLProps, useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import useGetUserInfo from 'api/queries/useGetUserInfo';
import { AppPath } from 'constants/global';
import { AuthContext } from 'context/AuthContext';
import useAuth from 'hooks/useAuth';

import './index.scss';

import AuthLayout from '../auth';
import MainLayout from '../main';

interface Props extends HTMLProps<HTMLDivElement> {
  isLoaded: boolean;
}
const AppLayout: FC<Props> = ({ isLoaded }) => {
  const navigate = useNavigate();
  const { isSignedIn, setIsSignedIn, setIsUserFirstTime } = useContext(AuthContext);
  const searchParams = new URLSearchParams(document.location.search);
  const newToken = searchParams.get('token');

  useEffect(() => {
    useAuth({ user: undefined, newToken, setIsSignedIn, navigate });
    searchParams.get('numberOfGenerations') !== null &&
      setIsUserFirstTime(searchParams.get('numberOfGenerations') === '0');
  }, [isSignedIn]);

  return (
    <div>
      {isLoaded && (
        <Routes>
          {isSignedIn ? (
            <Route path={AppPath.MAIN} element={<MainLayout />} />
          ) : (
            <Route path={AppPath.LOGIN} element={<AuthLayout />} />
          )}
        </Routes>
      )}
    </div>
  );
};

export default AppLayout;

import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import css from './index.module.scss';

import { DownloadButton } from '../DownloadButton';

import CloseIcon from '../../assets/svg/close-icon';
import { downloadImage } from '../../helpers/download-image';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
  originalImage: { id: string; url: string };
  refinedImage: { id: string; url: string };
}

export const DetailedImageComparisonModal: FC<Props> = ({ setVisible, originalImage, refinedImage }) => {
  const { t } = useTranslation();

  return (
    <div className={`${css.detail_popup}`}>
      <div className={`${css.image_comparison_popup_inner} ${css.detail_popup_inner}`}>
        <button onClick={() => setVisible(false)}>
          <CloseIcon className={css.close_icon} />
        </button>

        <div className={css.image_comparison_block}>
          {[originalImage, refinedImage].map((image, index) => (
            <div key={image.id} className={css.image_comparison_item}>
              <p className={css.image_comparison_title}>
                {t(index === 0 ? 'editModal.originalImage' : 'editModal.refinedImage')}
              </p>
              <img className={css.image_to_refine} src={image.url} alt={image.id} />
              <DownloadButton
                disabled={true}
                className={css.download_btn}
                onClick={() => {
                  if (!image) return;

                  // downloadImage(image.url, image.id);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

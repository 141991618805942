import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import css from './index.module.scss';

import PrimaryButton from '../PrimaryButton';

import { ArrowRightIcon } from '../../assets/svg/arrow-right-icon';
import { XIcon } from '../../assets/svg/x-icon';
import { downloadImage } from '../../helpers/download-image';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
  images: { id: string; url: string }[];
  initialImageIndex: number;
}

export const ImagePreviewModal: FC<Props> = ({ setVisible, images, initialImageIndex }) => {
  const { t } = useTranslation();

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(initialImageIndex);
  const [currentImage, setCurrentImage] = useState<{ id: string; url: string } | undefined>(undefined);

  useEffect(() => {
    setCurrentImage(images[currentImageIndex]);
  }, [images, currentImageIndex]);

  const goToTheImage = (index: number) => {
    if (!images[index]) return;
    setCurrentImageIndex(index);
  };

  if (!currentImage) return null;

  return (
    <div className={`log-in-form`}>
      <div className={`log-in-form-inner ${css.image_refiner_popup_inner}`}>
        <div className="log-in-top-bar">
          <p className="sign-in-text">{t('editModal.originalImage')}</p>
          <div className="log-in-x-icon" onClick={() => setVisible(false)}>
            <XIcon />
          </div>
        </div>
        <div className={css.image_preview_wr}>
          <ArrowRightIcon
            onClick={() => goToTheImage(currentImageIndex + 1)}
            className={`${css.navigate_arrow} ${css.navigate_arrow_left}  ${!images[currentImageIndex + 1] && css.disabled}`}
          />

          <div className={css.centered_image_wr}>
            <img className={css.image_to_refine} key={currentImage.id} src={currentImage.url} alt={currentImage.id} />
          </div>

          <ArrowRightIcon
            onClick={() => goToTheImage(currentImageIndex - 1)}
            className={`${css.navigate_arrow} ${!images[currentImageIndex - 1] && css.disabled}`}
          />
        </div>
        <PrimaryButton
          className={css.download_btn}
          disabled={true}
          title={t('global.download')}
          onClick={() => {
            const image = images[currentImageIndex];
            if (!image) return;
            // downloadImage(image.url, image.id);
          }}
        />
      </div>
    </div>
  );
};

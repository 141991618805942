import { CSSProperties, FC, ReactNode } from 'react';

import './index.scss';

interface Props {
  onClick: () => void;
  title: string;
  icon?: ReactNode;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
}

const SecondaryButton: FC<Props> = ({ onClick, title, disabled, className, style, icon }) => (
  <button disabled={disabled} className={`secondary-button ${className}`} style={style} onClick={onClick}>
    <span>
      {icon} {title}
    </span>
  </button>
);

export default SecondaryButton;

import { FC } from 'react';

import './index.scss';

interface Props {
  title?: string;
  src: string;
  onClick?: () => void;
}

const PresetOption: FC<Props> = ({ title, src, onClick }) => (
  <button className={`preset-option ${onClick ? '' : 'show-state'}`} onClick={onClick}>
    <img src={src} alt={title} />
    {title && <span className="title">{title}</span>}
  </button>
);

export default PresetOption;

import { FC, ReactNode, useContext, useMemo } from 'react';

import { t } from 'i18next';

import { GenerationStatuses } from 'constants/statuses';
import { AuthContext } from 'context/AuthContext';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';
import Banner from 'pages/generation/components/Banner';

import './index.scss';

import GeneratedBlock from '../GeneratedBlock';

interface Props {
  isLoading: boolean;
}

const TopBlock: FC<Props> = ({ isLoading }) => {
  const { generationStatus, activeTabIndex, isStarted, isReseted } = useContext(GenerationContext);
  const { isVisited: isVisitedLighting, isLightingReady } = useContext(LightingContext);
  const { isUserFirstTime } = useContext(AuthContext);
  const { isVisited: isVisitedDemographics, isDemographicsReady } = useContext(DemographicsContext);
  const { isVisited: isVisitedDetails, isPhysicalDetailsReady } = useContext(PhysicalDetailsContext);
  const { isVisited: isVisitedRender, iseRenderSettingsReady } = useContext(RenderSettingsContext);

  const allTabsVisited = isVisitedDemographics && isVisitedLighting && isVisitedDetails && isVisitedRender;
  const oneTabVisited = isVisitedDemographics || isVisitedLighting || isVisitedDetails || isVisitedRender;
  const anyVisitedButNotReady =
    (isVisitedDemographics && !isDemographicsReady) ||
    (isVisitedLighting && !isLightingReady) ||
    (isVisitedDetails && !isPhysicalDetailsReady) ||
    (isVisitedRender && !iseRenderSettingsReady);

  const titleMap: { [x: number]: ReactNode } = useMemo(() => {
    return {
      1: <p className="generate__page-tab-title">{t('generate.demographicsTitle')}</p>,
      2: <p className="generate__page-tab-title">{t('generate.whatKindOfShotDoYouWant')}</p>,
      3: <p className="generate__page-tab-title">{t('generate.howDoesThePersonaLook')}</p>,
      4: <p className="generate__page-tab-title">{t('generate.howManyRendersDoYouWant')}</p>,
      5: (
        <p className="generate__page-tab-title">
          {anyVisitedButNotReady ? (
            <>
              <span className="white">{t('generate.dirty.title')}</span>{' '}
              <span className="red">{t('generate.dirty.description')}</span>
            </>
          ) : !allTabsVisited ? (
            <>
              <span className="white">{t('generate.visited.choose')}</span> {t('generate.visited.yourSettings')}
            </>
          ) : (
            <>
              {t('generate.describeGeneration.describe')}
              <span className="white">{t('generate.describeGeneration.clothing')}</span>,
              <span className="white">{t('generate.describeGeneration.location')}</span>
              {t('generate.describeGeneration.and')}
              <span className="white">{t('generate.describeGeneration.activity')}</span>
              {t('generate.describeGeneration.ready')}
            </>
          )}
        </p>
      ),
    };
  }, [allTabsVisited, anyVisitedButNotReady, isUserFirstTime]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: generationStatus === GenerationStatuses.NONE ? 'center' : 'flex-start',
        height: 288,
      }}
    >
      {isReseted ? (
        <GeneratedBlock />
      ) : !isLoading ? (
        <>
          {generationStatus === GenerationStatuses.NONE ? (
            isUserFirstTime && (activeTabIndex === 6 || activeTabIndex === 5 || activeTabIndex === 3) ? (
              <Banner
                isPhysicalDetailsClicked={isStarted}
                isCrossPressed={activeTabIndex === 5}
                isPhysicalDetailsReady={!isPhysicalDetailsReady}
              />
            ) : (
              <>
                {activeTabIndex > 0 && activeTabIndex < 6 && titleMap[activeTabIndex]}
                {activeTabIndex === 6 &&
                  (!oneTabVisited ? (
                    <p className="generate__page-tab-title">{t('generate.welcomeBack')}</p>
                  ) : (
                    <p className="generate__page-tab-title">
                      <span className="red">{t('generate.clickOn')}</span>{' '}
                      <span className="white">{t('generate.tabsMarkedRed')}</span>{' '}
                      <span className="red">{t('generate.toFinishSetUp')}</span>
                    </p>
                  ))}
              </>
            )
          ) : (
            <GeneratedBlock />
          )}
        </>
      ) : null}
    </div>
  );
};

export default TopBlock;

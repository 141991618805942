import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

import { GenerationStatuses } from 'constants/statuses';
import { UpdateType, useUpdates } from 'hooks/useUpdates';

interface Context {
  resetGenerated: () => void;
  generatedData: any | undefined;
  generationStatus: GenerationStatuses;
  waitGeneratedImages: (updateType?: UpdateType) => void;
  setGenerationStatus: Dispatch<SetStateAction<GenerationStatuses>>;
}

export const NewGenerationContext = createContext<Context>({} as Context);

interface Props {
  children: ReactNode;
}

type GeneratedData = {
  id: string;
  images: { id: string; url: string }[];
  meta: Object;
  options: { imageWidth: number; imageHeight: number; numberOfImages: number };
  status: string;
};

export const NewGenerationProvider: FC<Props> = ({ children }) => {
  const [generatedData, setGeneratedData] = useState<GeneratedData>();
  const { setGenerationStatus, generationStatus, resetGenerated, waitGeneratedImages } =
    useUpdates<GeneratedData>(setGeneratedData);
  return (
    <NewGenerationContext.Provider
      value={{
        generatedData,
        setGenerationStatus,
        generationStatus,
        resetGenerated,
        waitGeneratedImages,
      }}
    >
      {children}
    </NewGenerationContext.Provider>
  );
};

import { FC } from 'react';

import './index.scss';

const SocialNetwork: FC<{ img: string; title: string; onClick?: () => void }> = ({ img, title, onClick }) => {
  return (
    <button
      style={{ opacity: onClick ? 1 : 0.5 }}
      disabled={!onClick}
      className="log-in__social-network"
      onClick={onClick}
    >
      <img className="log-in__social-network_logo" src={img} />
      <p className="log-in__social-network_text ">{title}</p>
    </button>
  );
};

export default SocialNetwork;

import { useEffect, useState } from 'react';

import { useMutation } from '@tanstack/react-query';

import api, { Queries } from 'api/axios';

const useDeleteDraft = () => {
  const [success, setSuccess] = useState(false);

  const { data, error, mutate } = useMutation<any, any>({
    mutationFn: () => api.delete<any>(Queries.GENERATION_DRAFTS_CURRENT).then(res => res.data),
    onSuccess() {
      setSuccess(true);
    },
  });

  const parsedError: string | undefined | null = error?.response?.data?.error?.message ?? error?.message;

  useEffect(() => {
    if (error) {
      console.log(`Error: UseDeactivateAccount - ${error.message}`);
    }
  }, [error]);

  return { error, data, parsedError, deleteDraft: mutate, success };
};

export default useDeleteDraft;

import { FC, useContext } from 'react';

import CheckIcon from 'assets/svg/check-icon';
import UncheckIcon from 'assets/svg/uncheck-icon';
import { GenerationStatuses } from 'constants/statuses';
import { GenerationContext } from 'context/GenerationContext';

interface Props {
  isActive: boolean;
  isCompleted: boolean;
  onClick: () => void;
  index: number;
  disabled: boolean;
  isVisited: boolean;
  title: string;
}

const TabItem: FC<Props> = ({ isActive, onClick, disabled, title, isVisited, isCompleted, index }) => {
  const { generationStatus } = useContext(GenerationContext);

  return (
    <div
      className={`generation__section-tab-row ${index !== 0 ? '' : isVisited && index === 0 ? 'show_settings' : 'hide_settings'}`}
    >
      <button
        disabled={disabled || generationStatus !== GenerationStatuses.NONE}
        className={`${index === 0 || index === 4 ? 'generation__section-tab_small' : 'generation__section-tab'} ${index === 0 ? 'settings' : ''} ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        {title}
      </button>
      <div className="state-icon">
        {index === 0 ? null : isCompleted && isVisited ? (
          <CheckIcon />
        ) : isVisited && !isActive ? (
          <UncheckIcon />
        ) : null}
      </div>
    </div>
  );
};

export default TabItem;

import { useContext, useEffect, useMemo } from 'react';

import useUpdateDraft from 'api/queries/useUpdateDraft';
import { RSelectOption } from 'components/Select';
import { DemographicsContext } from 'context/DemographicsContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';

const usePresetOptions = () => {
  const { age, gender, ethnicity } = useContext(DemographicsContext);
  const { shot, cameraType, lighting } = useContext(LightingContext);
  const { appearance, body, facialHair, expression, makeup, eyesColor, hairColor, skinTone, shavedHead } =
    useContext(PhysicalDetailsContext);
  const { updateDraft } = useUpdateDraft();

  const presetOptions = useMemo(
    () => [
      age,
      shavedHead,
      gender,
      ethnicity,
      lighting,
      shot,
      cameraType,
      appearance,
      body,
      facialHair,
      expression,
      makeup,
      eyesColor,
      hairColor,
      skinTone,
    ],
    [
      age,
      shavedHead,
      gender,
      ethnicity,
      lighting,
      shot,
      cameraType,
      appearance,
      body,
      facialHair,
      expression,
      makeup,
      eyesColor,
      hairColor,
      skinTone,
    ],
  );

  useEffect(() => {
    const presetOptionIds = presetOptions
      .filter((item): item is RSelectOption => item !== null && item !== undefined)
      .map(item => item.value);

    if (presetOptionIds.length > 1) updateDraft({ presetOptionIds });
  }, [presetOptions]);

  return { presetOptions };
};

export default usePresetOptions;

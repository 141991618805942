import { FC, useContext, useState } from 'react';

import { DetailedImageComparisonModal } from 'components/DetailedImageComparisonModal';
import GeneratedImages from 'components/GeneratedImages';
import { ImageRefinerModal } from 'components/ImageRefinerModal';
import { Ratios } from 'constants/images';
import { GenerationStatuses } from 'constants/statuses';
import { GenerationContext } from 'context/GenerationContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';

import './index.scss';

const GeneratedBlock: FC = () => {
  const { generationStatus, generatedData, isReseted } = useContext(GenerationContext);
  const { numberOfVariations, ratios } = useContext(RenderSettingsContext);
  const [isRefinerModalVisible, setRefinerModalVisible] = useState<boolean>(false);
  const [isImageComparisonModalVisible, setImageComparisonModalVisible] = useState<boolean>(false);

  const [imageIndexToEdit, setImageIndexToEdit] = useState<number>(0);

  const setImageImageIndexToEdit = (id: string) => {
    const images = generatedData!.images;
    const imageIndex = images.findIndex(image => image.id === id);
    if (imageIndex === undefined) return;

    setImageIndexToEdit(imageIndex);
    setRefinerModalVisible(true);
  };

  return (
    <>
      {(numberOfVariations && ratios) || isReseted ? (
        <>
          <GeneratedImages
            isReseted={isReseted}
            isLoading={generationStatus === GenerationStatuses.FETCHING}
            number={isReseted ? 4 : numberOfVariations!}
            ratios={isReseted ? Ratios.SQUARE : ratios!}
            images={generatedData?.images ?? []}
            imageClicked={setImageImageIndexToEdit}
          />
          {isRefinerModalVisible && generatedData && (
            <ImageRefinerModal setVisible={setRefinerModalVisible} editImageIndex={imageIndexToEdit} />
          )}

          {isImageComparisonModalVisible && generatedData && (
            <DetailedImageComparisonModal
              setVisible={setImageComparisonModalVisible}
              originalImage={generatedData.images[0]}
              refinedImage={generatedData.images[1]}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default GeneratedBlock;

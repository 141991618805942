import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';

export interface Character {
  id: string;
  previewUrl: string;
  presetOptionIds: string[];
  prompts: [
    {
      Clothing: string;
    },
    {
      Activity: string;
    },
  ];
}

const useCharacters = () => {
  const { isLoading, data, error, refetch } = useQuery<Character[], AxiosError>({
    queryKey: ['characters'],
    networkMode: 'always',
    queryFn: () => api.get<{ result: Character[] }>(Queries.CHARACTERS).then(res => res.data.result),
  });

  return { isLoading, error, data, refetch };
};

export default useCharacters;

import { FC } from 'react';

import './index.scss';

interface Props {
  active: boolean;
  disabled?: boolean;
  title: string;
  onClick?: () => void;
  iconAspect: number;
}
const RatioButton: FC<Props> = ({ active, disabled, title, onClick, iconAspect }) => (
  <button
    disabled={disabled}
    className={`variations-button ${active && 'active'}`}
    style={{ width: 132 }}
    onClick={onClick}
  >
    <p>{title}</p>
    <div
      className="ratio-button-shape"
      style={{
        borderColor: active ? undefined : '#878A00',
        background: active ? '#878A00' : '#3A3D40',
        aspectRatio: iconAspect,
        width: iconAspect === 1.5 ? '18px' : '12px',
        marginRight: iconAspect === 1.5 ? '0' : '3px',
      }}
    ></div>
  </button>
);

export default RatioButton;

import { useContext, useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';
import { pixelRatios } from 'constants/images';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';
import { LightingSelectorTitles } from 'pages/generation/components/Lighting/components/Selectors';

interface Generations {
  id: string;
  images: [];
  status: string;
}

export interface GenerateBody {
  clothing: string;
  activity: string;
  presetOptionIds: string[];
  makeup: boolean;
  height: number;
  width: number;
  numberOfImages: number;
}

const useGenerateCharacter = () => {
  const { error, data, mutate, isPending } = useMutation<Generations, AxiosError, any>({
    mutationFn: (body: GenerateBody) =>
      api.post<{ result: Generations }>(Queries.GENERATIONS_CHARACTER, body).then(res => res.data.result),
  });

  const generate = (characterId: string) => {
    mutate({ characterId });
  };

  useEffect(() => {
    if (error) {
      console.log(`Error: useGenerateCharacter - ${error.message}`);
    }
  }, [error]);

  return { error, data, generate, isPending };
};

export default useGenerateCharacter;

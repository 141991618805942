import { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useVariations, { Variations, VariationsDtoWithInstantImageResponse } from 'api/queries/useVariations';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import Spinner from 'components/Spinner';
import { GenerationStatuses } from 'constants/statuses';
import { GenerationContext } from 'context/GenerationContext';
import { RenderSettingsContext } from 'context/RenderSettingsContext';
import { downloadImage } from 'helpers/download-image';
import { UpdateType } from 'hooks/useUpdates';
import { useUpscaled } from 'hooks/useUpscaled';

import css from './index.module.scss';

import FullScreenModal from '../FullScreenModal';

interface ImageIndex {
  currentImageIndex: number;
  isShowAlfa: boolean;
  image?: { id: string; url: string };
  setDisabled?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
}

interface Image {
  currentImageIndex: number;
  isShowAlfa: boolean;
  image: { id: string; url: string };
  setDisabled?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
}

type Props = ImageIndex | Image;

export enum States {
  EDIT = 'edit',
  UPSCALE = 'upscale',
  NEW_GENERATE = 'newGenerate',
}

const UpscaledBlock: FC<Props> = ({ currentImageIndex, isShowAlfa, image, setDisabled, isLoading }) => {
  const { t } = useTranslation();
  const { data, isPending, generate } = useVariations();
  const [showFullScreen, setShowFullScreen] = useState(false);
  const { generatedData, upscaled, setUpscaled } = useContext(GenerationContext);

  const currentImage = currentImageIndex !== undefined ? generatedData?.images[currentImageIndex] : image;
  if (!currentImage) return null;

  const { generationStatus, upscaledData, setGeneratedDataAndStatus, waitGeneratedImages, resetGenerated } =
    useUpscaled();

  const [showDownloadWithUpscale, setShowDownloadWithUpscale] = useState(false);
  const alfaImage = useMemo(() => upscaled[currentImageIndex]?.images?.[1], [currentImageIndex, upscaled]);
  const bigImage = useMemo(() => {
    if (alfaImage && isShowAlfa) {
      return {
        id: 'alfaImage',
        url: alfaImage?.url,
      };
    }
    if (image) {
      return {
        id: image?.id,
        url: image?.url,
      };
    }
    return {
      id: upscaledData?.newImage.id,
      url: upscaledData?.newImage.url,
    };
  }, [currentImageIndex, upscaled, upscaledData, isShowAlfa]);

  const fullScreenImages = useMemo(() => {
    if (alfaImage && alfaImage.url && isShowAlfa) {
      return [currentImage.url, alfaImage.url];
    }
    if (image) {
      return [currentImage.url, image.url];
    }
    if (upscaledData?.newImage.url) {
      return [upscaledData.originalImage.url, upscaledData.newImage.url];
    }
    return [currentImage.url];
  }, [currentImage, upscaledData, isShowAlfa, alfaImage]);

  const isUpscaleDisabled = () =>
    generationStatus === GenerationStatuses.FETCHING || generationStatus === GenerationStatuses.FETCHED || isLoading;

  useEffect(() => {
    if (data?.status === 'SUCCESS') {
      setGeneratedDataAndStatus(data as VariationsDtoWithInstantImageResponse, GenerationStatuses.FETCHED);
      return;
    }

    if (data?.status === 'PENDING') {
      waitGeneratedImages(UpdateType.VariationStatusUpdate, currentImage.id);
    }
  }, [data?.status]);

  useEffect(() => {
    if (generationStatus === GenerationStatuses.FETCHED) {
      setUpscaled(prev => {
        const t = [...prev];

        if (currentImageIndex !== undefined) {
          t[currentImageIndex] = { images: [{ url: upscaledData?.newImage.url, type: Variations.UPSCALE }] } || {};
        }

        return t;
      });
    }
    if (generationStatus === GenerationStatuses.FETCHING) {
      setDisabled && setDisabled(true);
    } else {
      setDisabled && setDisabled(false);
    }
  }, [generationStatus]);

  useEffect(() => {
    if (currentImageIndex !== undefined) {
      const images = upscaled[currentImageIndex]?.images;

      if (images && images[0].url) {
        const currentData = {
          id: `${currentImageIndex}`,
          status: 'SUCCESS',
          originalImage: {
            id: generatedData?.images[currentImageIndex].id || '',
            url: generatedData?.images[currentImageIndex].url || '',
          },
          newImage: { id: `${currentImageIndex}`, url: images[0].url },
        };
        setGeneratedDataAndStatus(currentData, GenerationStatuses.FETCHED);
      } else {
        resetGenerated();
      }
    }
  }, [currentImageIndex, upscaled]);

  useEffect(() => {
    isPending && setDisabled && setDisabled(true);
    !isPending && setDisabled && setDisabled(false);
  }, [isPending]);

  return (
    <div className={css.edit_generated__images_container}>
      <div className={css.edit_generated__original_image_block}>
        {!isShowAlfa && (
          <img
            onLoad={() => setShowDownloadWithUpscale(true)}
            className={`${css.image_to_refine} ${image || isLoading ? css.small_image : generationStatus === GenerationStatuses.NONE ? css.big_image : css.small_image}`}
            key={currentImage.id}
            src={currentImage.url}
            alt={currentImage.id}
          />
        )}
        <div className={css.upscaled_image_block}>
          {(generationStatus === GenerationStatuses.FETCHING || isPending || isLoading) && <Spinner />}
          {(generationStatus === GenerationStatuses.FETCHED || upscaled[currentImageIndex] !== undefined || image) && (
            <img
              className={`${css.upscaled_image} ${css.big_image}`}
              key={bigImage.id}
              src={bigImage.url}
              alt={bigImage.id}
            />
          )}
        </div>
      </div>
      {showDownloadWithUpscale && (
        <div className={css.button_block}>
          <div className={`${css.unupscaled_button_block} ${isShowAlfa ? css.full : ''}`}>
            {!isShowAlfa && (
              <SecondaryButton
                disabled
                title={t('global.download')}
                className={`${css.download_btn}`}
                onClick={() => {
                  if (!currentImage) return;
                  downloadImage(currentImage.url, currentImage.id);
                }}
              />
            )}
            <PrimaryButton
              className={css.upscale_button}
              disabled={isUpscaleDisabled()}
              onClick={() => {
                if (image) {
                  downloadImage(image.url, image.id);
                } else if (alfaImage && alfaImage?.url) {
                  downloadImage(alfaImage.url, 'alfaImage');
                } else if (generationStatus === GenerationStatuses.NONE) {
                  generate(currentImage.id, Variations.UPSCALE);
                } else if (upscaledData?.newImage) {
                  downloadImage(upscaledData?.newImage.url, upscaledData?.newImage.id);
                }
              }}
              title={t(
                image || isLoading || generationStatus !== GenerationStatuses.NONE
                  ? 'global.download'
                  : 'editModal.upscale',
              )}
            />
          </div>
          <SecondaryButton
            title={t('editModal.fullScreen')}
            className={`${css.download_btn} ${isShowAlfa ? css.full : ''}`}
            onClick={() => {
              if (!currentImage) return;
              setShowFullScreen(true);
            }}
          />
        </div>
      )}
      <FullScreenModal images={fullScreenImages} show={showFullScreen} onClose={() => setShowFullScreen(false)} />
    </div>
  );
};

export default UpscaledBlock;

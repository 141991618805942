export enum Ratios {
  PORTRAIT = '2:3',
  ALBUM = '3:2',
  SQUARE = '1:1',
}

export const pixelRatios: Record<Ratios, { width: number; height: number }> = {
  [Ratios.PORTRAIT]: { height: 1024, width: 688 },
  [Ratios.ALBUM]: { height: 688, width: 1024 },
  [Ratios.SQUARE]: { height: 1024, width: 1024 },
};

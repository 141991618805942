import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';

import { EventSourcePolyfill } from 'event-source-polyfill';
import Cookies from 'js-cookie';

import { GenerationStatuses } from 'constants/statuses';

export enum UpdateType {
  GenerationStatusUpdate = 'GENERATION_STATUS_UPDATE',
  GenerationStatusUpdateError = 'GENERATION_STATUS_UPDATE_ERROR',
  VariationStatusUpdate = 'VARIATION_STATUS_UPDATE',
  VariationStatusUpdateError = 'VARIATION_STATUS_UPDATE_ERROR',
}

export const useUpdates = <T extends unknown>(setGeneratedData: Dispatch<SetStateAction<T | undefined>>) => {
  const [generationStatus, setGenerationStatus] = useState<GenerationStatuses>(GenerationStatuses.NONE);

  const showError = (message: string) => {
    toast.error(message, {
      hideProgressBar: true,
    });
    setGenerationStatus(GenerationStatuses.NONE);
  };

  const onError = (e: any) => {
    console.log('>>>>>>>', e);
    //showError('Error connecting server');
  };

  const waitGeneratedImages = (updateType?: UpdateType, originalImageId?: string) => {
    if (generationStatus !== GenerationStatuses.FETCHED) {
      setGenerationStatus(GenerationStatuses.FETCHING);
      const es = new EventSourcePolyfill(`${process.env.REACT_APP_BASE_URL}updates`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('token')}`,
        },
      });
      es.onerror = onError;
      es.onmessage = e => {
        const eventData = JSON.parse(e?.data);
        if (
          eventData.type === UpdateType.GenerationStatusUpdateError ||
          eventData.type === UpdateType.VariationStatusUpdateError
        ) {
          showError(eventData.payload.message);
        } else if (updateType === eventData.type && updateType === UpdateType.VariationStatusUpdate) {
          if (eventData.payload.originalImage.id === originalImageId) {
            setGeneratedDataAndStatus(eventData.payload, GenerationStatuses.FETCHED);
            es.close();
          }
        } else if (updateType === eventData.type && updateType === UpdateType.GenerationStatusUpdate) {
          setGeneratedDataAndStatus(eventData.payload, GenerationStatuses.FETCHED);
          es.close();
        } else if (updateType === undefined) {
          setGeneratedDataAndStatus(eventData.payload, GenerationStatuses.FETCHED);
          es.close();
        }
      };
    }
  };

  const setGeneratedDataAndStatus = (data: T | undefined, status: GenerationStatuses) => {
    setGeneratedData(data);
    setGenerationStatus(status);
  };

  const resetGenerated = (isRefetch = false) => {
    setGenerationStatus(isRefetch ? GenerationStatuses.FETCHING : GenerationStatuses.NONE);
    setGeneratedData(undefined);
  };

  return {
    setGenerationStatus,
    generationStatus,
    resetGenerated,
    waitGeneratedImages,
    setGeneratedDataAndStatus,
  };
};

import { combineProviders } from 'react-combine-providers';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'styles/general.scss';

import { DemographicsProvider } from 'context/DemographicsContext';
import { GenerationProvider } from 'context/GenerationContext';
import { LightingProvider } from 'context/LightingContext';
import { NewGenerationProvider } from 'context/NewGenerationContext';
import { PhysicalDetailsProvider } from 'context/PhysicalDetailsContext';
import { RenderSettingsProvider } from 'context/RenderSettingsContext';

import './App.scss';
import { AuthProvider } from './context/AuthContext';
import AppLayout from './layouts/app';
import './translations/i18n';

const provider = combineProviders();
provider.push(AuthProvider);
provider.push(GenerationProvider);
provider.push(NewGenerationProvider);
provider.push(PhysicalDetailsProvider);
provider.push(RenderSettingsProvider);
provider.push(LightingProvider);
provider.push(DemographicsProvider);
const MasterProvider = provider.master();

const App = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <MasterProvider>
        <BrowserRouter>
          <AppLayout className="app" isLoaded={true} />
        </BrowserRouter>
      </MasterProvider>
    </QueryClientProvider>
  );
};

export default App;

// <GoogleOAuthProvider clientId={String(process.env.REACT_APP_GOOGLE_ID)}>
// </GoogleOAuthProvider>,

import { useInfiniteQuery } from '@tanstack/react-query';

import api, { Queries } from 'api/axios';

import { GeneratedHistoryItem } from '../models/generated-history';
import { WithPaging } from '../models/paging-models';

export const useGenerationHistory = (page: number = 1, perPage: number = 10) => {
  const { fetchNextPage, data, isFetchingNextPage, error, refetch } = useInfiniteQuery({
    queryKey: ['generationHistory'],
    queryFn: ({ pageParam = 1 }) =>
      api
        .get<WithPaging<GeneratedHistoryItem[]>>(Queries.GENERATIONS, {
          params: {
            'paging[page]': pageParam,
            'paging[perPage]': perPage,
          },
        })
        .then(res => {
          // todo refactor map for  meta data
          const respData: WithPaging<GeneratedHistoryItem[]> = res.data;

          return {
            paging: respData.paging,
            result: respData.result.map(item => ({
              ...item,
            })),
          };
        }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => allPages.length + 1,
  });

  return { isFetchingNextPage, error, data, refetch, fetchNextPage };
};

import { CSSProperties } from 'react';

const DashboardTabIcon = ({
  style,
  color,
  className,
}: {
  className?: string;
  style?: CSSProperties;
  color?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    style={style}
    className={className}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.01348 0H2.97574C1.33089 0 0 1.34244 0 2.99552V6.05963C0 7.71203 1.33117 9.05515 2.97574 9.05515H6.01348C7.65805 9.05515 8.98922 7.71203 8.98922 6.05963V2.99552C8.98922 1.34244 7.65833 0 6.01348 0ZM2.97574 1.39535H6.01348C6.88499 1.39535 7.59387 2.11039 7.59387 2.99552V6.05963C7.59387 6.94418 6.88461 7.6598 6.01348 7.6598H2.97574C2.10461 7.6598 1.39535 6.94418 1.39535 6.05963V2.99552C1.39535 2.11039 2.10423 1.39535 2.97574 1.39535ZM17.0252 0H13.9865C12.3417 0 11.0108 1.34244 11.0108 2.99552V6.05963C11.0108 7.71203 12.342 9.05515 13.9865 9.05515H17.0252C18.669 9.05515 20 7.71184 20 6.05963V2.99552C20 1.34264 18.6693 0 17.0252 0ZM13.9865 1.39535H17.0252C17.8959 1.39535 18.6047 2.11049 18.6047 2.99552V6.05963C18.6047 6.94407 17.8955 7.6598 17.0252 7.6598H13.9865C13.1154 7.6598 12.4061 6.94418 12.4061 6.05963V2.99552C12.4061 2.11039 13.115 1.39535 13.9865 1.39535ZM2.97574 10.9449H6.01348C7.65852 10.9449 8.98922 12.2875 8.98922 13.9413V17.0045C8.98922 18.6576 7.65833 20 6.01348 20H2.97574C1.33089 20 0 18.6576 0 17.0045V13.9413C0 12.2875 1.3307 10.9449 2.97574 10.9449ZM6.01348 12.3402H2.97574C2.10413 12.3402 1.39535 13.0553 1.39535 13.9413V17.0045C1.39535 17.8896 2.10423 18.6047 2.97574 18.6047H6.01348C6.88499 18.6047 7.59387 17.8896 7.59387 17.0045V13.9413C7.59387 13.0553 6.88509 12.3402 6.01348 12.3402ZM17.0252 10.9449H13.9865C12.3415 10.9449 11.0108 12.2875 11.0108 13.9413V17.0045C11.0108 18.6576 12.3417 20 13.9865 20H17.0252C18.6693 20 20 18.6574 20 17.0045V13.9413C20 12.2877 18.6695 10.9449 17.0252 10.9449ZM13.9865 12.3402H17.0252C17.896 12.3402 18.6047 13.0554 18.6047 13.9413V17.0045C18.6047 17.8895 17.8959 18.6047 17.0252 18.6047H13.9865C13.115 18.6047 12.4061 17.8896 12.4061 17.0045V13.9413C12.4061 13.0553 13.1149 12.3402 13.9865 12.3402Z"
      fill={color ? color : '#7E7E7E'}
    />
  </svg>
);
export default DashboardTabIcon;

import { Dispatch, FC, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PresetOption, PresetTypes } from 'api/models/presets';
import useDraft from 'api/queries/useDraft';
import useGenerationPresets from 'api/queries/useGenerationPresets';
import SecondaryButton from 'components/SecondaryButton';
import { RoutePath } from 'constants/routes';
import { GenerationContext } from 'context/GenerationContext';
import { getOptionsByType } from 'helpers/draft';
import useSetDraftOptions from 'hooks/useSetDraftOptions';

import { createTextRow, getRendersOptions } from '../utils';

interface Props {
  setHistoryPrompts: Dispatch<SetStateAction<[{ Clothing: string }, { Activity: string }] | undefined>>;
  setHistoryPromptModalVisible: Dispatch<SetStateAction<boolean>>;
}

const DraftBlock: FC<Props> = ({ setHistoryPrompts, setHistoryPromptModalVisible }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { data: draft } = useDraft();
  const { data: generationPresets } = useGenerationPresets();
  const { resetGenerated } = useContext(GenerationContext);
  const { setDraft } = useSetDraftOptions({ draft, generationPresets });

  const getLocalPreset = (preset: PresetTypes.DEMOGRAPHICS | PresetTypes.PHYSICAL_DETAILS | PresetTypes.LIGHTING) => {
    const { optionsByType } = getOptionsByType(generationPresets);
    return optionsByType[preset].filter((e: PresetOption) => draft?.presetOptionIds.includes(e.id));
  };

  const getDraftItems = () => (
    <>
      {createTextRow('Date', [
        {
          id: '',
          title: '',
        },
      ])}
      {createTextRow(PresetTypes.DEMOGRAPHICS, getLocalPreset(PresetTypes.DEMOGRAPHICS))}
      {createTextRow(PresetTypes.LIGHTING, getLocalPreset(PresetTypes.LIGHTING))}
      {createTextRow(PresetTypes.PHYSICAL_DETAILS, getLocalPreset(PresetTypes.PHYSICAL_DETAILS))}
      {getRendersOptions('Renders', draft?.width, draft?.height, draft?.numberOfImages)}
    </>
  );

  // todo refactor after api response change, especially meta data format
  return (
    <>
      {draft && (draft.presetOptionIds.length !== 0 || draft.activity || draft.clothing) && (
        <div className="dashboard__generation-info-container">
          <div className="dashboard__info-values-container">
            {getDraftItems()}
            <div className="dashboard__history_action_buttons">
              <SecondaryButton
                className={'dashboard__action_button'}
                onClick={() => {
                  setDraft();
                  resetGenerated();
                  navigate(RoutePath.GENERATE);
                }}
                title={t('global.continue')}
              />
              {(draft.clothing || draft.activity) && (
                <SecondaryButton
                  className={'dashboard__action_button'}
                  onClick={() => {
                    setHistoryPrompts([{ Clothing: draft.clothing }, { Activity: draft.activity }]);
                    setHistoryPromptModalVisible(true);
                  }}
                  title={t('dashboard.description')}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DraftBlock;

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GeneratedHistoryImage, GeneratedHistoryItem } from 'api/models/generated-history';
import { PresetTypes } from 'api/models/presets';
import useGenerate from 'api/queries/useGenerate';
import { useGenerationHistory } from 'api/queries/useGenerationHistory';
import GeneratedImages from 'components/GeneratedImages';
import { HistoryPromptModal } from 'components/HistoryPromptDialog';
import { ImagePreviewModal } from 'components/ImagePreviewModal';
import PrimaryButton from 'components/PrimaryButton';
import SecondaryButton from 'components/SecondaryButton';
import { RoutePath } from 'constants/routes';
import { GenerationContext } from 'context/GenerationContext';

import DraftBlock from './DraftBlock';
import './index.scss';
import { createTextRow, getImageAspectRatio, getRendersOptions } from './utils';

const CreationHistory: FC = () => {
  const { t } = useTranslation();

  const { data, isFetchingNextPage, fetchNextPage } = useGenerationHistory();

  const [isImagePreviewModalVisible, setImagePreviewModalVisible] = useState<boolean>(false);
  const [isHistoryPromptModalVisible, setHistoryPromptModalVisible] = useState<boolean>(false);
  const [historyPrompts, setHistoryPrompts] = useState<[{ Clothing: string }, { Activity: string }]>();
  const navigate = useNavigate();
  const { data: newGenerate, isPending, generate } = useGenerate();
  const { resetGenerated, waitGeneratedImages } = useContext(GenerationContext);

  const onRegenerate = (session: GeneratedHistoryItem) => {
    const presetBody = {
      clothing: session.meta.prompts[0].Clothing,
      activity: session.meta.prompts[1].Activity,
      presetOptionIds: session.meta.presetOptionIds,
      makeup: session.meta.makeup,
      height: session.options.imageHeight,
      width: session.options.imageWidth,
      numberOfImages:
        session.options.numberOfImages === 2 || session.options.numberOfImages === 4
          ? session.options.numberOfImages
          : 4,
    };
    if (session.meta.presetOptionIds.length === 9) {
      resetGenerated();
      // setPreset(presetBody);
      generate(presetBody);
    }
  };

  useEffect(() => {
    if (newGenerate?.status === 'PENDING') {
      navigate(RoutePath.GENERATE);
      waitGeneratedImages();
    }
  }, [newGenerate?.status]);

  const [imageIndexToPreview, setImageIndexToPreview] = useState<number>(0);
  const [sessionImagesToPreview, setSessionImagesToPreview] = useState<GeneratedHistoryImage[]>([]);

  const hasLoadButton = useMemo(() => {
    const firstPage = data?.pages[0];
    if (!firstPage) return false;
    const pageCount = Math.ceil(firstPage.paging.total / firstPage.paging.limit);
    return data?.pages.length < pageCount;
  }, [data]);

  const setImageImageIndexToEdit = (id: string, sessionImages: GeneratedHistoryImage[]) => {
    const imageIndex = sessionImages.findIndex(image => image.id === id);
    if (imageIndex === undefined) return;

    setImageIndexToPreview(imageIndex);
    setSessionImagesToPreview(sessionImages);
    setImagePreviewModalVisible(true);
  };

  return (
    <>
      <div className="dashboard__history-container">
        <p className="dashboard__history-title">{t('dashboard.generationHistory')}</p>
        <div className="dashboard__images-block">
          {data &&
            data.pages.map((page, index) => (
              <div key={index} className="dashboard__images-list">
                <DraftBlock
                  setHistoryPrompts={setHistoryPrompts}
                  setHistoryPromptModalVisible={setHistoryPromptModalVisible}
                />
                {page.result.map(session => (
                  <div key={session.id}>
                    <GeneratedImages
                      isLoading={false}
                      // remove later currently work only with 2 and 4 images
                      number={
                        session.options.numberOfImages !== 2 && session.options.numberOfImages !== 4
                          ? 2
                          : session.options.numberOfImages
                      }
                      ratios={getImageAspectRatio(session.options.imageWidth, session.options.imageHeight)}
                      className="dashboard__history-images_container"
                      images={session.images}
                      imageClicked={id => setImageImageIndexToEdit(id, session.images)}
                    />

                    {session.meta && (
                      <div className="dashboard__generation-info-container">
                        <div className="dashboard__info-values-container">
                          {createTextRow('Date', [
                            {
                              id: '',
                              title: new Date(session.createdAt).toLocaleDateString(),
                            },
                          ])}
                          {createTextRow(PresetTypes.DEMOGRAPHICS, session.meta['Demographics'])}
                          {createTextRow(PresetTypes.LIGHTING, session.meta['Camera, Shot & Lighting'])}
                          {createTextRow(PresetTypes.PHYSICAL_DETAILS, session.meta['Physical Details'])}
                          {getRendersOptions(
                            'Renders',
                            session.options.imageWidth,
                            session.options.imageHeight,
                            session.options.numberOfImages,
                          )}

                          <div className="dashboard__history_action_buttons">
                            <SecondaryButton
                              className="dashboard__action_button"
                              disabled={session.meta.presetOptionIds.length !== 15 || isPending || true}
                              onClick={() => {
                                onRegenerate(session);
                              }}
                              title={t('dashboard.regenerate')}
                            />
                            <SecondaryButton
                              className="dashboard__action_button"
                              disabled={isPending}
                              onClick={() => {
                                setHistoryPrompts(session.meta!.prompts);
                                setHistoryPromptModalVisible(true);
                              }}
                              title={t('dashboard.description')}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
        {hasLoadButton && (
          <PrimaryButton
            disabled={isFetchingNextPage}
            className="dashboard__load-more-btn"
            onClick={() => {
              fetchNextPage();
            }}
            title={t('dashboard.loadMore')}
          />
        )}
      </div>
      {isHistoryPromptModalVisible && historyPrompts && (
        <HistoryPromptModal
          setVisible={setHistoryPromptModalVisible}
          clothing={historyPrompts[0].Clothing}
          activity={historyPrompts[1].Activity}
        />
      )}
      {isImagePreviewModalVisible && (
        <ImagePreviewModal
          setVisible={setImagePreviewModalVisible}
          images={sessionImagesToPreview}
          initialImageIndex={imageIndexToPreview}
        />
      )}
    </>
  );
};

export default CreationHistory;

const ArrowDownIcon = ({ className }: { className?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" className={className} height="8" fill="none" viewBox="0 0 14 8">
    <path
      fill="#fff"
      d="M.534.455a.676.676 0 01.893-.068l.077.068L7 6.06 12.496.455a.676.676 0 01.893-.068l.077.068a.71.71 0 01.066.91l-.066.08-5.981 6.1a.676.676 0 01-.893.068l-.077-.068-5.98-6.1a.71.71 0 010-.99z"
    ></path>
  </svg>
);

export default ArrowDownIcon;

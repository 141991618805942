import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Dashboard from 'pages/dashboard';
import Generation from 'pages/generation';

import './index.scss';

import Header from '../../components/Header';
import { RoutePath } from '../../constants/routes';

const MainLayout: FC = () => (
  <div className="container">
    <Header />
    <Routes>
      <Route path={RoutePath.GENERATE} element={<Generation />} />
      <Route path={RoutePath.DASHBOARD} element={<Dashboard />} />
      <Route path="*" element={<Navigate to={RoutePath.GENERATE} replace />} />
    </Routes>
  </div>
);

export default MainLayout;

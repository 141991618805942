import { FC, useState } from 'react';

import LogInBox from 'components/LogInBox';

import Banner from './components/Banner';
import Footer from './components/Footer';
import './index.scss';

import Header from '../../components/Header';

export const SignIn: FC = () => {
  const [isLoginPopUp, setIsLoginPopUp] = useState<boolean>(false);

  return (
    <div className="container">
      <Header />
      <Banner />
      <Footer setVisible={setIsLoginPopUp} />
      {isLoginPopUp ? <LogInBox setVisible={setIsLoginPopUp} /> : null}
    </div>
  );
};

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Preset } from 'api/models/presets';
import Select, { RSelectOption } from 'components/Select';
import { AuthContext } from 'context/AuthContext';
import { DemographicsContext } from 'context/DemographicsContext';
import { GenerationContext } from 'context/GenerationContext';
import { getOptions } from 'helpers/select';

import './index.scss';

interface Props {
  presets?: Preset[];
}

export enum DemographicSelectorTitles {
  AGE = 'Age',
  GENDER = 'Gender',
  AFRICAN_DIASPORA = 'African diaspora',
  EUROPEAN_ANCESTRY = 'European ancestry',
  ASIAN_ANCESTRY = 'Asian ancestry',
  LATIN_INDIGENOUS_OCEANIA = 'Latin, Indigenous, Oceania +',
}

const Selectors: FC<Props> = ({ presets }) => {
  const { t } = useTranslation();
  const { age, gender, ancestry, ethnicity, setAncestry, setEthnicity, setAge, setGender } =
    useContext(DemographicsContext);
  const { isReseted } = useContext(GenerationContext);
  const { isUserFirstTime } = useContext(AuthContext);
  const [selectStates, setSelectStates] = useState<{
    african?: RSelectOption;
    european?: RSelectOption;
    asian?: RSelectOption;
    ocean?: RSelectOption;
  }>({});

  const ancestryOptions = useMemo(
    () => presets?.slice(2).map(preset => ({ value: preset.id, label: preset.title })),
    [presets],
  );

  const handleChangeEthnicity = (value: RSelectOption) => {
    const ancestryCurrent = ancestryOptions?.find(item => item.label === value.parent);
    if (ancestryCurrent?.value !== ancestry?.value) setAncestry(ancestryCurrent);
    setEthnicity(value);

    const newSelectStates: {
      african?: RSelectOption;
      european?: RSelectOption;
      asian?: RSelectOption;
      ocean?: RSelectOption;
    } = {};

    switch (value.parent) {
      case DemographicSelectorTitles.AFRICAN_DIASPORA:
        newSelectStates.african = value;
        break;
      case DemographicSelectorTitles.EUROPEAN_ANCESTRY:
        newSelectStates.european = value;
        break;
      case DemographicSelectorTitles.ASIAN_ANCESTRY:
        newSelectStates.asian = value;
        break;
      case DemographicSelectorTitles.LATIN_INDIGENOUS_OCEANIA:
        newSelectStates.ocean = value;
        break;
      default:
        break;
    }
    setSelectStates(newSelectStates);
  };

  useEffect(() => {
    if (ethnicity) {
      handleChangeEthnicity(ethnicity);
    }
  }, [isUserFirstTime]);

  useEffect(() => {
    isReseted && setSelectStates({});
  }, [isReseted]);

  return (
    <div className="generate-lighting-selectors_container">
      <div className="generate-lighting-selectors-block_container ">
        <Select
          onChange={setAge}
          disabled={isUserFirstTime}
          value={age}
          className="generate-lighting-selector"
          options={getOptions(presets, DemographicSelectorTitles.AGE)}
          placeholder={t('global.age')}
        />
        <Select
          onChange={handleChangeEthnicity}
          disabled={isUserFirstTime}
          value={selectStates.african}
          className="generate-lighting-selector"
          options={getOptions(presets, DemographicSelectorTitles.AFRICAN_DIASPORA)}
          placeholder={DemographicSelectorTitles.AFRICAN_DIASPORA}
        />
        <Select
          onChange={handleChangeEthnicity}
          className="generate-lighting-selector"
          disabled={isUserFirstTime}
          value={selectStates.asian}
          options={getOptions(presets, DemographicSelectorTitles.ASIAN_ANCESTRY)}
          placeholder={DemographicSelectorTitles.ASIAN_ANCESTRY}
        />
      </div>
      <div className="generate-lighting-selectors-block_container ">
        <Select
          onChange={setGender}
          disabled={isUserFirstTime}
          className="generate-lighting-selector"
          value={gender}
          options={getOptions(presets, DemographicSelectorTitles.GENDER)}
          placeholder={t('global.gender')}
        />
        <Select
          onChange={handleChangeEthnicity}
          className="generate-lighting-selector"
          disabled={isUserFirstTime}
          value={selectStates.european}
          options={getOptions(presets, DemographicSelectorTitles.EUROPEAN_ANCESTRY)}
          placeholder={DemographicSelectorTitles.EUROPEAN_ANCESTRY}
        />
        <Select
          onChange={handleChangeEthnicity}
          disabled={isUserFirstTime}
          className="generate-lighting-selector"
          value={selectStates.ocean}
          options={getOptions(presets, DemographicSelectorTitles.LATIN_INDIGENOUS_OCEANIA)}
          placeholder={DemographicSelectorTitles.LATIN_INDIGENOUS_OCEANIA}
        />
        <p className="generate-mid-description" style={{ top: 104 }}>
          {t('generate.chooseAnEthnicity')}
        </p>
      </div>
    </div>
  );
};

export default Selectors;

import { FC, useContext, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { Presets } from 'api/models/presets';
import { Character } from 'api/queries/useCharacters';
import useDeleteDraft from 'api/queries/useDeleteDraft';
import useGenerateCharacter from 'api/queries/useGenerateCharacter';
import CloseIcon from 'assets/svg/close-icon';
import PresetOption from 'components/PresetOption';
import { GenerationStatuses } from 'constants/statuses';
import { AuthContext } from 'context/AuthContext';
import { GenerationContext } from 'context/GenerationContext';
import useSetDraftOptions from 'hooks/useSetDraftOptions';

import './index.scss';

interface Props {
  title: string;
  show: boolean;
  subtitle: string;
  onClose: () => void;
  generationPresets: Presets | undefined;
  options: Character[];
  columns: 2 | 3;
}

const SelectModal: FC<Props> = ({ show, title, options, subtitle, onClose, generationPresets }) => {
  const { generate, data } = useGenerateCharacter();
  const {
    waitGeneratedImages,
    setIsReseted,
    setActiveTabIndex,
    setUpscaled,
    resetGenerated,
    generationStatus,
    setInspireImage,
  } = useContext(GenerationContext);
  const { isUserFirstTime, setIsUserFirstTime } = useContext(AuthContext);
  const { deleteDraft } = useDeleteDraft();

  const handleClick = () => {
    setUpscaled([]);
    setIsReseted(false);
    resetGenerated(true);
    onClose();
  };
  useEffect(() => {
    if (data?.status === 'PENDING') {
      isUserFirstTime && setIsUserFirstTime(false);
      waitGeneratedImages();
    }
  }, [data?.status]);

  useEffect(() => {
    if (generationStatus === GenerationStatuses.FETCHED) {
      deleteDraft();
      setInspireImage('');
      setActiveTabIndex(5);
    }
  }, [generationStatus]);

  return (
    <>
      {createPortal(
        <div className={`select-modal-container ${show ? 'show' : ''}`}>
          <div className="select-modal-inner">
            <div
              className="select-modal-inner-container"
              style={{ width: 880, gap: 0, paddingTop: 6, paddingBottom: 16 }}
            >
              <div className="title-container" style={{ justifyContent: 'flex-start', gap: 8 }}>
                <p className="text">{title}</p>
                <p className="text dark">{subtitle}</p>
                {!isUserFirstTime && (
                  <button
                    className="first-time-generation__physical-details-close-btn"
                    style={{ marginLeft: 'auto', marginRight: 8 }}
                    onClick={onClose}
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
              <div className="presets-container" style={{ gap: 30, marginTop: 5 }}>
                {options.map((option, index) => {
                  const lastGeneratedToDraft = useMemo(() => {
                    return {
                      id: '',
                      activity: option.prompts[1].Activity,
                      clothing: option.prompts[0].Clothing,
                      width: 1024,
                      height: 1024,
                      numberOfImages: 4 as 4,
                      presetOptionIds: option.presetOptionIds,
                    };
                  }, [option]);
                  const { setDraft: setPreset } = useSetDraftOptions({
                    draft: lastGeneratedToDraft,
                    generationPresets,
                  });
                  return (
                    <PresetOption
                      key={index}
                      src={option.previewUrl!}
                      onClick={() => {
                        generate(option.id);
                        setInspireImage(option.previewUrl!);
                        setPreset();
                        handleClick();
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};

export default SelectModal;

import { FC, useContext, useEffect, useState } from 'react';

import { PresetTypes } from 'api/models/presets';
import useGenerationPresets from 'api/queries/useGenerationPresets';
import CloseIcon from 'assets/svg/close-icon';
import { LightingContext } from 'context/LightingContext';

import Selectors from './components/Selectors';
import './index.scss';

interface Props {
  onClose: () => void;
  isActive: boolean;
}

const Lighting: FC<Props> = ({ onClose, isActive }) => {
  const { data } = useGenerationPresets();
  const { setIsVisited } = useContext(LightingContext);
  const [isClose, setIsClose] = useState(false);

  const handleClose = () => {
    onClose();
    setIsClose(true);
  };

  useEffect(() => {
    if (isActive) {
      setIsVisited(true);
    } else {
      setIsClose(false);
    }
  }, [isActive]);

  return (
    <div
      className={`first-time-generation__physical-details-container ${isActive && !isClose ? 'active' : isActive && isClose ? 'hide' : ''}`}
    >
      <Selectors presets={data && data[PresetTypes.LIGHTING]} />
      <button className="first-time-generation__physical-details-close-btn" onClick={handleClose}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default Lighting;

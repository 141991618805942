import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Preset } from 'api/models/presets';
import Select, { RSelectOption } from 'components/Select';
import { AuthContext } from 'context/AuthContext';
import { GenerationContext } from 'context/GenerationContext';
import { LightingContext } from 'context/LightingContext';
import { getOptions } from 'helpers/select';

import './index.scss';

interface Props {
  presets?: Preset[];
}

export enum LightingSelectorTitles {
  CAMERA = 'Camera',
  CAMERA_TYPE = 'Camera type',
  SHOT = 'Shot',
  LIGHTING_TYPE = 'Lighting type',
  OUTDOOR_LIGHTING = 'Outdoor lighting',
  INDOOR_LIGHTING = 'Indoor lighting',
  BLACK_AND_WHITE_SETTINGS = 'Black & White settings',
}

const Selectors: FC<Props> = ({ presets }) => {
  const { t } = useTranslation();
  const { shot, setShot, cameraType, setCameraType, lightingType, setLightingType, lighting, setLighting } =
    useContext(LightingContext);
  const { isReseted } = useContext(GenerationContext);
  const { isUserFirstTime } = useContext(AuthContext);
  const [selectStates, setSelectStates] = useState<{
    outdoor?: RSelectOption;
    indoor?: RSelectOption;
    blackAndWhite?: RSelectOption;
  }>({});

  const lightingTypeOptions = useMemo(
    () => presets?.slice(2).map(preset => ({ value: preset.id, label: preset.title })),
    [presets],
  );

  const handleChangeLight = (value: RSelectOption) => {
    const lightingTypeCurrent = lightingTypeOptions?.find(item => item.label === value.parent);
    if (lightingTypeCurrent?.value !== lightingType?.value) setLightingType(lightingTypeCurrent);
    setLighting(value);

    const newSelectStates: {
      outdoor?: RSelectOption;
      indoor?: RSelectOption;
      blackAndWhite?: RSelectOption;
    } = {};

    switch (value.parent) {
      case LightingSelectorTitles.OUTDOOR_LIGHTING:
        newSelectStates.outdoor = value;
        break;
      case LightingSelectorTitles.INDOOR_LIGHTING:
        newSelectStates.indoor = value;
        break;
      case LightingSelectorTitles.BLACK_AND_WHITE_SETTINGS:
        newSelectStates.blackAndWhite = value;
        break;
      default:
        break;
    }
    setSelectStates(newSelectStates);
  };

  useEffect(() => {
    if (lighting) {
      handleChangeLight(lighting);
    }
  }, [isUserFirstTime]);

  useEffect(() => {
    isReseted && setSelectStates({});
  }, [isReseted]);

  return (
    <div className="generate-lighting-selectors_container">
      <div className="generate-lighting-selectors-block_container ">
        <Select
          onChange={value => {
            setCameraType(value);

            if (lightingType && lightingType?.label === LightingSelectorTitles.BLACK_AND_WHITE_SETTINGS) {
              setLighting(undefined);
              setLightingType(undefined);
              setSelectStates({});
            }
          }}
          disabled={isUserFirstTime}
          value={cameraType}
          className="generate-lighting-selector"
          options={presets
            ?.find(el => el.title === LightingSelectorTitles.CAMERA_TYPE)
            ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }))}
          placeholder={t('global.cameraType')}
        />
        <Select
          onChange={handleChangeLight}
          disabled={isUserFirstTime}
          value={selectStates.outdoor}
          className="generate-lighting-selector"
          options={getOptions(presets, LightingSelectorTitles.OUTDOOR_LIGHTING)}
          placeholder={LightingSelectorTitles.OUTDOOR_LIGHTING}
        />
      </div>
      <div className="generate-lighting-selectors-block_container ">
        <Select
          onChange={value => {
            setShot(value);
          }}
          className="generate-lighting-selector"
          value={shot}
          disabled={isUserFirstTime}
          options={presets
            ?.find(el => el.title === LightingSelectorTitles.SHOT)
            ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }))}
          placeholder={t('global.shot')}
        />
        <Select
          onChange={handleChangeLight}
          disabled={isUserFirstTime}
          value={selectStates.indoor}
          className="generate-lighting-selector"
          options={getOptions(presets, LightingSelectorTitles.INDOOR_LIGHTING)}
          placeholder={LightingSelectorTitles.INDOOR_LIGHTING}
        />
        <Select
          onChange={value => {
            handleChangeLight(value);
            setCameraType(undefined);
          }}
          disabled={isUserFirstTime}
          value={selectStates.blackAndWhite}
          className="generate-lighting-selector"
          options={getOptions(presets, LightingSelectorTitles.BLACK_AND_WHITE_SETTINGS)}
          placeholder={LightingSelectorTitles.BLACK_AND_WHITE_SETTINGS}
        />
        <p className="generate-mid-description">{t('generate.chooseIndoorOrOutdoor')}</p>
        <p className="generate-description">{t('generate.theseSettingsInclude')}</p>
      </div>
    </div>
  );
};

export default Selectors;

export enum PresetTypes {
  PHYSICAL_DETAILS = 'Physical Details',
  DEMOGRAPHICS = 'Demographics',
  LIGHTING = 'Camera, Shot & Lighting',
}

export type PresetOption = {
  id: string;
  title: string;
  previewUrl?: string;
};

export type Preset = {
  id: string;
  title: string;
  presetOptions: PresetOption[];
};

type PresetCategory = {
  id: string;
  title: PresetTypes;
  presets: Preset[];
};

export interface PresetsDto {
  result: { presetCategory: PresetCategory }[];
}

export interface Presets {
  [PresetTypes.DEMOGRAPHICS]?: Preset[];
  [PresetTypes.PHYSICAL_DETAILS]?: Preset[];
  [PresetTypes.LIGHTING]?: Preset[];
}

export const mapPresetsDtoToPresets = (dto: PresetsDto): Presets => {
  const colorAttributes = dto.result.find(element => element.presetCategory.title === PresetTypes.DEMOGRAPHICS);
  const general = dto.result.find(element => element.presetCategory.title === PresetTypes.PHYSICAL_DETAILS);
  const lighting = dto.result.find(element => element.presetCategory.title === PresetTypes.LIGHTING);

  return {
    [PresetTypes.DEMOGRAPHICS]: colorAttributes?.presetCategory.presets,
    [PresetTypes.PHYSICAL_DETAILS]: general?.presetCategory.presets,
    [PresetTypes.LIGHTING]: lighting?.presetCategory.presets,
  };
};

import { useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';

export type GeneratedData = {
  id: string;
  status: string;
  originalImage: { id: string; url: string };
  newImage: { id: string; url: string };
};

export enum Variations {
  UPSCALE = 'UPSCALE',
  NO_BACKGROUND = 'NO_BACKGROUND',
}

export interface VariationsDto {
  id: string;
  status: string;
}

export interface VariationsDtoWithInstantImageResponse extends GeneratedData {
  id: string;
  status: string;
}

const useVariations = () => {
  const { error, data, mutate, isPending } = useMutation<
    VariationsDto | VariationsDtoWithInstantImageResponse,
    AxiosError,
    any
  >({
    mutationFn: (body: any) =>
      api
        .post<{ result: VariationsDto | VariationsDtoWithInstantImageResponse }>(Queries.VARIATIONS, body)
        .then(res => res.data.result),
  });

  // const parsedError: string | undefined | null = error?.response?.data?.error?.message ?? error?.message;

  const generate = (imageId: string, type: Variations) => {
    mutate({
      imageId: imageId,
      type: type,
    });
  };

  useEffect(() => {
    if (error) {
      console.log(`Error: useSendResetInstructions - ${error.message}`);
    }
  }, [error]);

  return { error, data, generate, isPending };
};

export default useVariations;

import { FC } from 'react';
import { createPortal } from 'react-dom';

import { PresetOption as IPresetOption } from 'api/models/presets';
import CloseIcon from 'assets/svg/close-icon';
import PresetOption from 'components/PresetOption';

import './index.scss';

interface Props {
  title: string;
  show: boolean;
  onClose: () => void;
  onSelect: (value: IPresetOption) => void;
  options: IPresetOption[];
  columns: 2 | 3;
}

const SelectModal: FC<Props> = ({ show, title, options, onClose, onSelect }) => {
  const handleClick = (value: IPresetOption) => {
    onSelect(value);
    onClose();
  };

  return (
    <>
      {createPortal(
        <div className={`select-modal-container ${show ? 'show' : ''}`}>
          <div className="select-modal-inner">
            <div className="select-modal-inner-container">
              <div className="log-in-top-bar">
                <p className="sign-in-text">{title}</p>
                <button className="first-time-generation__physical-details-close-btn" onClick={onClose}>
                  <CloseIcon />
                </button>
              </div>
              <div className="presets-container">
                {options.map((option, index) => (
                  <PresetOption
                    key={index}
                    title={option.title}
                    src={option.previewUrl!}
                    onClick={() => handleClick(option)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};

export default SelectModal;

import { FC } from 'react';

import { t } from 'i18next';

import './index.scss';

const PhysicalDetailsBanner: FC = () => (
  <div className="details-guide-banner">
    <p className="banner-text banner-red banner-finish">
      {t('firstTimeGeneration.physicalDetailsBanner.click')}
      <span className="banner-text banner-contrast">
        {t('firstTimeGeneration.physicalDetailsBanner.physicalDetails')}
      </span>
      {t('firstTimeGeneration.physicalDetailsBanner.chooseAllSettings')}
    </p>
  </div>
);

export default PhysicalDetailsBanner;

import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';

import PrimaryButton from '../../../../components/PrimaryButton';

const AccountInfo: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="dashboard-account-info-container">
      <div className="user-info-box">
        <p className="account-info-title">{t('dashboard.accountInformation')}</p>
        <div className={'info_block_rows'}>
          <div className={'info_block'}>
            <div className={'info_block_title'}>User ID</div>
            <div className={'info_block_description'}>12345678</div>
          </div>
          <div className={'info_block'}>
            <div className={'info_block_title'}>Name of subscription plan</div>
            <div className={'info_block_description'}>Basic</div>
          </div>
          <div className={'info_block'}>
            <div className={'info_block_title'}>Plan renews MM/DD/YYYY</div>
            <div className={'info_block_description'}>22/07/2024</div>
          </div>
          <div className={'info_block'}>
            <div className={'info_block_title'}>XX/Total monthly credits</div>
            <div className={'info_block_description'}>22/07/2024</div>
          </div>
        </div>
      </div>
      <PrimaryButton onClick={() => {}} className={'manage-account-button'} title={t('dashboard.manageAccount')} />
    </div>
  );
};

export default AccountInfo;

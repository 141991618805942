import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import css from './index.module.scss';

import { XIcon } from '../../assets/svg/x-icon';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
  activity: string;
  clothing: string;
}

export const HistoryPromptModal: FC<Props> = ({ setVisible, activity, clothing }) => {
  const { t } = useTranslation();
  return (
    <div className={`log-in-form `}>
      <div className={`log-in-form-inner ${css.prompt_description_popup_inner}`}>
        <div className="log-in-top-bar">
          <p className="sign-in-text">{t('dashboard.description')}</p>
          <div className="log-in-x-icon" onClick={() => setVisible(false)}>
            <XIcon />
          </div>
        </div>

        <div className={css.prompts_block}>
          <div className={'info_column'}>
            <div className={'row_title'}>{t('global.clothing')}</div>
            <span>{clothing}</span>
          </div>

          <div className={'info_column'}>
            <div className={'row_title'}>{t('global.activityAndLocations')}</div>
            <span>{activity}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

import { FC } from 'react';

import { t } from 'i18next';

const CreationGuideBanner: FC = () => (
  <div className="banner-description">
    <p className="banner-text banner-finish">
      {t('firstTimeGeneration.creationGuideBanner.chooseFrom')}
      <span className="banner-text banner-contrast">
        {' '}
        {t('firstTimeGeneration.creationGuideBanner.theDetailsBelow')}
      </span>
      . {t('firstTimeGeneration.creationGuideBanner.clickThe')}
      <span className="banner-text banner-gray"> {t('firstTimeGeneration.creationGuideBanner.closeIcon')}</span>{' '}
      {t('firstTimeGeneration.creationGuideBanner.whenDone')}
    </p>
  </div>
);

export default CreationGuideBanner;

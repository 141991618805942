import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';

interface Context {
  isSignedIn: boolean;
  setIsSignedIn: Dispatch<SetStateAction<boolean>>;
  isUserFirstTime: boolean;
  setIsUserFirstTime: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<Context>({} as Context);

interface Props {
  children: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(true);
  const [isUserFirstTime, setIsUserFirstTime] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        setIsSignedIn,
        isUserFirstTime,
        setIsUserFirstTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import bannerImage1 from 'assets/png/BannerImage1.png';
import bannerImage2 from 'assets/png/BannerImage2.png';
import bannerImage3 from 'assets/png/BannerImage3.png';
import bannerImage4 from 'assets/png/BannerImage4.png';
import Animation1 from 'assets/svg/animation/animation1';
import Animation2 from 'assets/svg/animation/animation2';
import Animation3 from 'assets/svg/animation/animation3';
import Animation4 from 'assets/svg/animation/animation4';
import Animation5 from 'assets/svg/animation/animation5';
import Animation6 from 'assets/svg/animation/animation6';

import './index.scss';

import OptionsBlock from '../OptionsBlock';

const Banner: FC = () => {
  const { t } = useTranslation();
  const animationPictures = [
    <Animation1 key={1} />,
    <Animation2 key={2} />,
    <Animation3 key={3} />,
    <Animation4 key={4} />,
    <Animation5 key={5} />,
    <Animation6 key={6} />,
  ];
  const [currentAnimationPicture, setCurrentAnimationPicture] = useState<number>(0);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentAnimationPicture(prev => {
  //       if (prev === 5) {
  //         return 0;
  //       } else {
  //         return prev + 1;
  //       }
  //     });
  //   }, 3000);
  //   return () => clearInterval(intervalId);
  // }, []);

  return (
    <div className="log-in__banner-container">
      <p className="log-in__banner-header-text">Create images like this</p>
      <div className="log-in__banner-images_container">
        <img src={bannerImage1} className="log-in__banner-image" alt="bannerImage" />
        <img src={bannerImage2} className="log-in__banner-image" alt="bannerImage" />
        <img src={bannerImage3} className="log-in__banner-image" alt="bannerImage" />
        <img src={bannerImage4} className="log-in__banner-image" alt="bannerImage" />
      </div>
      <div className="log-in__banner-description-container">
        <div>
          <p className="log-in__banner-description">Welcome to the</p>
          <p className="log-in__banner-description">
            <span className="log-in__banner-description-contrast">prompt-free</span> AI
          </p>
          <p className="log-in__banner-description">
            <span className="log-in__banner-description-contrast">character creation</span> of...
          </p>
        </div>
        <p className="log-in__banner-description">{t('global.expressMode')}.</p>
      </div>
      {/* {animationPictures[currentAnimationPicture]} */}
      {/* <OptionsBlock /> */}
    </div>
  );
};

export default Banner;

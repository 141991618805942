import { FC } from 'react';

import './index.scss';

const Spinner: FC = () => (
  <div className="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Spinner;

export const DownloadIcon = ({ className, onClick }: { className?: string; onClick?: () => void }) => (
  <svg
    className={className}
    onClick={onClick && onClick}
    viewBox="0 0 128 150"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.791 0H56.8348L56.8348 23.9297L56.8348 89.6309L21.0463 53.8424L10.4707 64.418L63.3488 117.296L63.5322 117.113L64.4891 118.069L117.367 65.1914L106.021 53.8448L71.791 88.0743L71.791 23.9297V0ZM127.127 137.155V149.996H-0.000599469L-0.00060003 137.155H127.127Z"
    />
  </svg>
);

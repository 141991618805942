import { FC } from 'react';

interface Props {
  className?: string;
}

const UncheckIcon: FC<Props> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="24" height="24" rx="12" fill="#F44336" />
  </svg>
);

export default UncheckIcon;

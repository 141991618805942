import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon } from 'assets/svg/arrow-right-icon';
import CloseIcon from 'assets/svg/close-icon';

import css from './index.module.scss';

import arrowCss from '../../index.module.scss';

interface Props {
  images: string[];
  show: boolean;
  onClose: () => void;
}

const FullScreenModal: FC<Props> = ({ images, show, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { t } = useTranslation();

  const goToTheImage = (index: number) => {
    setCurrentImageIndex(index);
  };

  const handleClose = () => {
    setCurrentImageIndex(0);
    onClose();
  };

  return (
    <div className={`${css.full_screen_modal_container} ${show ? css.show : ''}`}>
      <div className={css.full_screen_modal}>
        <button
          className={`${css.close_button} first-time-generation__physical-details-close-btn`}
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        <div className={css.image_container}>
          <img className={css.current_image} src={images[currentImageIndex]} alt={currentImageIndex.toString()} />
        </div>
        <div className={css.action_block}>
          <p className={css.action_title}>{t(!currentImageIndex ? 'editModal.original' : 'editModal.upscaled')}</p>
          {images.length > 1 && (
            <div className={arrowCss.navigate_arrows}>
              {!!currentImageIndex && (
                <ArrowRightIcon
                  onClick={() => {
                    if (!images[currentImageIndex - 1]) return;
                    goToTheImage(currentImageIndex - 1);
                  }}
                  className={`${arrowCss.navigate_arrow} ${arrowCss.navigate_arrow_left}`}
                />
              )}
              {!currentImageIndex && (
                <ArrowRightIcon
                  onClick={() => {
                    if (!images[currentImageIndex + 1]) return;
                    goToTheImage(currentImageIndex + 1);
                  }}
                  className={`${arrowCss.navigate_arrow}`}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullScreenModal;

import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';

import useGenerateNewPrompt from 'api/queries/useGenerateNewPrompt';
import CloseIcon from 'assets/svg/close-icon';
import { GenerationContext } from 'context/GenerationContext';
import { NewGenerationContext } from 'context/NewGenerationContext';
import { UpdateType } from 'hooks/useUpdates';

import Edit from './components/Edit';
import css from './index.module.scss';

import { XIcon } from '../../assets/svg/x-icon';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
  editImageIndex: number;
}

export const ImageRefinerModal: FC<Props> = ({ setVisible, editImageIndex }) => {
  const { generatedData } = useContext(GenerationContext);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(editImageIndex);
  const { data: newData, generate: generateNew } = useGenerateNewPrompt(generatedData?.id ?? '');
  const { resetGenerated, waitGeneratedImages: waitNewGeneratedImages } = useContext(NewGenerationContext);

  useEffect(() => {
    if (newData?.status === 'PENDING') {
      waitNewGeneratedImages(UpdateType.GenerationStatusUpdate);
    }
  }, [newData?.status]);

  useEffect(() => {
    resetGenerated();
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div className={css.image_refiner_modal}>
      <div className={css.image_refiner_popup_inner}>
        <button
          className={`${css.close_button} first-time-generation__physical-details-close-btn`}
          onClick={() => setVisible(false)}
        >
          <CloseIcon />
        </button>
        <Edit
          generateNew={generateNew}
          setCurrentImageIndex={setCurrentImageIndex}
          currentImageIndex={currentImageIndex}
        />
      </div>
    </div>
  );
};

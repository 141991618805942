import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import apple from 'assets/png/apple.png';
import facebook from 'assets/png/facebook.png';
import google from 'assets/png/google.png';
import { XIcon } from 'assets/svg/x-icon';

import SocialNetwork from './components';
import './index.scss';

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const LogInBox: FC<Props> = ({ setVisible }) => {
  const GoogleLogIn = () => {
    window.open(process.env.REACT_APP_BASE_URL + 'oauth2/google/callback', '_self', 'noopener,noreferrer');
  };

  const { t } = useTranslation();
  return (
    <div className="log-in-form">
      <div className="log-in-form-inner">
        <div className="log-in-top-bar">
          <p className="sign-in-text">{t('signIn.signIn')}</p>
          <div className="log-in-x-icon" onClick={() => setVisible(false)}>
            <XIcon />
          </div>
        </div>
        <div className="log-in__social-network_container">
          <SocialNetwork img={google} title={t('signIn.signInWithGoogle')} onClick={GoogleLogIn} />
          <SocialNetwork img={facebook} title={t('signIn.signInWithFacebook')} />
          <SocialNetwork img={apple} title={t('signIn.signInWithApple')} />
        </div>
      </div>
    </div>
  );
};

export default LogInBox;

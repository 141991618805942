import { FC, useContext } from 'react';

import Spinner from 'components/Spinner';
import { Ratios } from 'constants/images';

import './index.scss';

interface Props {
  number: 2 | 4;
  images: { id: string; url: string }[];
  ratios: Ratios;
  isLoading: boolean;
  isReseted?: boolean;
  imageClicked?: (id: string) => void;
  className?: string;
}

const GeneratedImages: FC<Props> = ({ number, images, isReseted, ratios, isLoading, imageClicked, className }) => {
  const styles = {
    '4': {
      [Ratios.PORTRAIT]: {
        container: {},
        item: { aspectRatio: 2 / 3 },
      },
      [Ratios.SQUARE]: {
        container: { justifyContent: 'space-between' },
        item: { aspectRatio: 1 / 1, width: '100%' },
      },
      [Ratios.ALBUM]: {
        container: { justifyContent: 'space-between', alignItems: 'center' },
        item: { aspectRatio: 3 / 2, flex: 1, maxHeight: '70%' },
      },
    },
    '2': {
      [Ratios.PORTRAIT]: {
        container: { gap: '5%' },
        item: { aspectRatio: 2 / 3 },
      },
      [Ratios.SQUARE]: {
        container: { gap: '5%' },
        item: { aspectRatio: 1 / 1 },
      },
      [Ratios.ALBUM]: {
        container: { justifyContent: 'space-between' },
        item: { aspectRatio: 3 / 2, flex: 1 },
      },
    },
  };

  const onImageClicked = (id: string) => {
    imageClicked && imageClicked(id);
  };

  const imagesBlock = images.map(image => (
    <div key={image.id} className="generate__generated-image_container" style={{ ...styles[number][ratios].item }}>
      <img
        className="generate__generated-image"
        onClick={() => {
          onImageClicked(image.id);
        }}
        src={image.url}
      />
    </div>
  ));

  const emptyItem = (
    <div className="generate__generation-loading-item" style={{ ...styles[number][ratios].item }}>
      <Spinner />
    </div>
  );
  const boxes = Array(number).fill(emptyItem);

  const resetedItem = <div className="generate__generation-loading-item" style={{ ...styles[number][ratios].item }} />;
  const resetedBoxes = Array(number).fill(resetedItem);

  return (
    <div className={className ? className : 'generated-block-container'} style={styles[number][ratios].container}>
      {isReseted ? resetedBoxes : isLoading ? boxes : imagesBlock}
    </div>
  );
};

export default GeneratedImages;

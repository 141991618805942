import { PresetOption, Presets, PresetTypes } from 'api/models/presets';

export const getOptionsByType = (generationPresets: Presets | undefined) => {
  const presets = [PresetTypes.DEMOGRAPHICS, PresetTypes.PHYSICAL_DETAILS, PresetTypes.LIGHTING];
  const optionsByType = presets?.reduce(
    (acc, value) => {
      const options: PresetOption[] = [];
      generationPresets && generationPresets[value]?.map(item => options.push(...item.presetOptions));
      return { ...acc, [value]: options };
    },
    { [PresetTypes.DEMOGRAPHICS]: [], [PresetTypes.PHYSICAL_DETAILS]: [], [PresetTypes.LIGHTING]: [] },
  );

  return { optionsByType, presets };
};

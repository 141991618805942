import { FC, FocusEventHandler, HTMLInputTypeAttribute } from 'react';

import './index.scss';

interface Props {
  type?: HTMLInputTypeAttribute;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  name?: string;
  maxLength?: number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  className?: string;
}

const TextInput: FC<Props> = ({
  type,
  label,
  onBlur,
  disabled,
  placeholder,
  maxLength,
  value,
  onChange,
  name,
  className,
}) => (
  <div className={`input_wrapper ${className}`}>
    {label && <label className="text-input-label">{label}</label>}
    <input
      onBlur={onBlur}
      className="text-input"
      type={type}
      maxLength={maxLength}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={event => {
        onChange && onChange(event.target.value);
      }}
      name={name}
    />
    {maxLength && <p className="text-input-size-counter ">{(value?.length ?? 0) + '/' + maxLength}</p>}
  </div>
);

export default TextInput;

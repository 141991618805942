import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthRoutePath } from 'constants/routes';
import { SignIn } from 'pages/signIn';

import './index.scss';

const AuthLayout: FC = () => (
  <Routes>
    <Route path={AuthRoutePath.LOGIN} element={<SignIn />} />
  </Routes>
);

export default AuthLayout;

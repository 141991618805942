import { CSSProperties, FC } from 'react';

import './index.scss';

interface Props {
  onClick: () => void;
  title: string;
  className?: string;
  style?: CSSProperties;
  active?: boolean;
  icon: JSX.Element;
}

const NavigationButton: FC<Props> = ({ onClick, icon, title, active, className, style }) => {
  return (
    <button disabled={active} className={`navigation-button ${className}`} style={style} onClick={onClick}>
      <div className="navigation-button_logo">{icon}</div>
      <span> {title}</span>
    </button>
  );
};

export default NavigationButton;

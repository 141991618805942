import { CSSProperties, FC } from 'react';

import './index.scss';

interface Props {
  onClick: () => void;
  title: string;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
}

const PrimaryButton: FC<Props> = ({ onClick, title, disabled, className, style }) => (
  <button disabled={disabled} className={`primary-button ${className}`} style={style} onClick={onClick}>
    <span> {title}</span>
  </button>
);

export default PrimaryButton;

import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';
import { UserInfo } from 'api/models/UserInfo';

const useGetUserInfo = () => {
  const { isLoading, data, error, refetch } = useQuery<UserInfo, AxiosError>({
    queryKey: ['me'],
    queryFn: () =>
      api.get<{ result: UserInfo }>(Queries.PROFILE).then(res => {
        return res.data.result;
      }),
  });

  return { isLoading, error, data, refetch };
};

export default useGetUserInfo;

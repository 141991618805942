import { FC } from 'react';

import './index.scss';

interface Props {
  active: boolean;
  disabled?: boolean;
  title: string;
  onClick?: () => void;
}
const VariationsButton: FC<Props> = ({ active, disabled = false, title, onClick }) => (
  <button disabled={disabled} className={`variations-button ${active && 'active'}`} onClick={onClick}>
    <div style={{ borderColor: active ? undefined : '#3A3D40' }} className="variations-button-outer-circle">
      {active && <div className="variations-button-inner-circle" />}
    </div>
    <p>{title}</p>
  </button>
);

export default VariationsButton;

import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import DashboardTabIcon from 'assets/svg/dashboard-tab-icon';
import GenerateTabIcon from 'assets/svg/generate-tab-icon';
import SecondaryButton from 'components/SecondaryButton';
import { RoutePath } from 'constants/routes';
import { AuthContext } from 'context/AuthContext';

import './index.scss';

import NavigationButton from '../NavigationButton';

const ButtonsBlock: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setIsSignedIn } = useContext(AuthContext);
  const isGenerate = location.pathname === '/generate';
  const isDashboard = location.pathname === '/dashboard';

  const logout = () => {
    Cookies.remove('token');
    setIsSignedIn(false);
    navigate('../');
  };

  const onDashboardClick = () => {
    navigate(RoutePath.DASHBOARD);
  };

  const onGenerateClick = () => {
    navigate(RoutePath.GENERATE);
  };

  return (
    <div className="header_navigate-buttons-container">
      <NavigationButton
        active={isGenerate}
        icon={<GenerateTabIcon className="header__button_icon" color={isGenerate ? 'white' : ''} />}
        onClick={onGenerateClick}
        title={t('global.generate')}
      />
      <NavigationButton
        active={isDashboard}
        icon={<DashboardTabIcon className="header__button_icon" color={isDashboard ? 'white' : ''} />}
        onClick={onDashboardClick}
        title={t('header.dashboard')}
      />
      <SecondaryButton className="logout-button" onClick={logout} title={t('global.logout')} />
    </div>
  );
};

export default ButtonsBlock;

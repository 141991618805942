import { Dispatch, SetStateAction, useState } from 'react';

import { GenerationStatuses } from 'constants/statuses';
import { UpdateType, useUpdates } from 'hooks/useUpdates';

export type GeneratedData = {
  id: string;
  status: string;
  originalImage: { id: string; url: string };
  newImage: { id: string; url: string };
};

interface Upscaled {
  resetGenerated: () => void;
  upscaledData: GeneratedData | undefined;
  generationStatus: GenerationStatuses;
  waitGeneratedImages: (updateType?: UpdateType, imageId?: string) => void;
  setGenerationStatus: Dispatch<SetStateAction<GenerationStatuses>>;
  setGeneratedDataAndStatus: (data: GeneratedData | undefined, status: GenerationStatuses) => void;
}

export const useUpscaled = (): Upscaled => {
  const [upscaledData, setUpscaledData] = useState<GeneratedData>();
  const { setGenerationStatus, generationStatus, resetGenerated, waitGeneratedImages, setGeneratedDataAndStatus } =
    useUpdates<GeneratedData>(setUpscaledData);
  return {
    upscaledData,
    setGenerationStatus,
    generationStatus,
    setGeneratedDataAndStatus,
    resetGenerated,
    waitGeneratedImages,
  };
};

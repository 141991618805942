import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';
import { pixelRatios, Ratios } from 'constants/images';

interface Body {
  clothing?: string;
  activity?: string;
  presetOptionIds?: string[];
  height?: number;
  width?: number;
  numberOfImages?: 2 | 4;
}

interface Mutate {
  clothing?: string;
  activity?: string;
  presetOptionIds?: string[];
  ratios?: Ratios;
  numberOfVariations?: 2 | 4;
}

const useUpdateDraft = () => {
  const { error, data, mutate, isPending } = useMutation<any, AxiosError, any>({
    mutationFn: (body: Body) =>
      api.post<{ result: boolean }>(Queries.GENERATION_DRAFTS, body).then(res => {
        // res.data.result &&
        //   toast.success('Saved', {
        //     hideProgressBar: true,
        //   });
      }),
  });

  const updateDraft = ({ clothing, activity, presetOptionIds, ratios, numberOfVariations }: Mutate) => {
    const body: Body = {};
    clothing !== undefined && (body.clothing = clothing);
    activity !== undefined && (body.activity = activity);
    presetOptionIds !== undefined && (body.presetOptionIds = presetOptionIds);
    ratios && pixelRatios[ratios]?.height !== undefined && (body.height = pixelRatios[ratios]?.height);
    ratios && pixelRatios[ratios]?.width !== undefined && (body.width = pixelRatios[ratios].width);
    numberOfVariations !== undefined && (body.numberOfImages = numberOfVariations);
    mutate(body);
  };

  useEffect(() => {
    if (error) {
      console.log(`Error: useSendResetInstructions - ${error.message}`);
    }
  }, [error]);

  return { error, data, updateDraft, isPending };
};

export default useUpdateDraft;

import axios from 'axios';
import Cookies from 'js-cookie';

export const updateLanguage = (lang: string) => {
  api.defaults.headers['accept-language'] = lang;
};

const COMMON_HEADERS = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Allow-Origin': '*',
  withCredentials: true,
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: COMMON_HEADERS,
});

export enum Queries {
  PRESETS = 'presets',
  GENERATIONS = 'generations',
  GENERATIONS_CHARACTER = 'generations/from-character',
  VARIATIONS = 'variations',
  GENERATION_DRAFTS = 'generation-drafts',
  GENERATION_DRAFTS_CURRENT = 'generation-drafts/current',
  PROFILE = 'users/me',
  CHARACTERS = 'characters/random',
  BACKGROUND_IMAGE = 'background-images/random',
}

api.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    const headers = {
      Authorization: 'Bearer ' + token,
    };

    config.headers['Authorization'] = headers.Authorization;

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  res => res,
  async err => {
    console.log(err);
    // Unauth user if it's 401 error
    if (err.response.status === 401) {
      Cookies.remove('token');
    }

    return Promise.reject(err);
  },
);

export default api;

import { FC } from 'react';

import AccountInfo from './components/AccountInfo';
import CreationHistory from './components/CreationHistory';
import './index.scss';

const Dashboard: FC = () => (
  <div className="dashboard-container">
    <div className="dashboard-content-container">
      <AccountInfo />
      <CreationHistory />
    </div>
  </div>
);

export default Dashboard;

import { Preset } from 'api/models/presets';

const usePrefillOption = (presets: Preset[] | undefined, title: string, label: string) => {
  return presets
    ?.find(el => el.title === title)
    ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title, preview: preset.previewUrl }))
    .find(el => el.label === label);
};

export default usePrefillOption;

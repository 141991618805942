import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from 'react-switch';

import { Preset } from 'api/models/presets';
import Select from 'components/Select';
import { GenerationContext } from 'context/GenerationContext';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';

import './index.scss';

interface Props {
  presets?: Preset[];
}

enum SelectorTitles {
  APPEARANCE = 'Appearance',
  BODY = 'Body type',
  MAKEUP = 'Makeup',
  EXPRESSION = 'Expression',
  FACIAL_HAIR = 'Facial hair',
  SHAVED_HEAD = 'Shaved head',
}

const Selectors: FC<Props> = ({ presets }) => {
  const {
    setAppearance,
    setBody,
    setFacialHair,
    setExpression,
    setMakeup,
    appearance,
    body,
    facialHair,
    expression,
    makeup,
    shavedHead,
    setShavedHead,
  } = useContext(PhysicalDetailsContext);
  const [shavedHeadToggle, setShavedHeadToggle] = useState(shavedHead ? shavedHead.label === 'Shaved' : false);
  const { isReseted } = useContext(GenerationContext);
  const { t } = useTranslation();

  useEffect(() => {
    const isShaved = shavedHead ? shavedHead.label === 'Shaved' : false;
    isShaved !== shavedHeadToggle && setShavedHeadToggle(isShaved);
  }, [shavedHead]);

  useEffect(() => {
    const shavedHeadPreset = presets
      ?.find(el => el.title === SelectorTitles.SHAVED_HEAD)
      ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }));
    console.log(shavedHeadPreset);
    if (shavedHeadPreset) {
      setShavedHead(
        shavedHeadPreset.find(item => (shavedHeadToggle ? item.label === 'Shaved' : item.label === 'None')),
      );
    }
  }, [shavedHeadToggle, presets, isReseted]);

  return (
    <div className="select-block-container">
      <div className="select-block generate-selectors-block">
        <Select
          onChange={value => {
            setAppearance(value);
          }}
          value={appearance}
          className="select"
          options={presets
            ?.find(el => el.title === SelectorTitles.APPEARANCE)
            ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }))}
          placeholder={t('global.appearance')}
        />
        <Select
          onChange={value => {
            setBody(value);
          }}
          className="select"
          value={body}
          options={presets
            ?.find(el => el.title === SelectorTitles.BODY)
            ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }))}
          placeholder={t('global.bodyType')}
        />
        <Select
          onChange={value => {
            setMakeup(value);
          }}
          className="select"
          value={makeup}
          options={presets
            ?.find(el => el.title === SelectorTitles.MAKEUP)
            ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }))}
          placeholder={t('global.makeup')}
        />
        <Select
          onChange={value => {
            setExpression(value);
          }}
          className="select"
          value={expression}
          options={presets
            ?.find(el => el.title === SelectorTitles.EXPRESSION)
            ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }))}
          placeholder={t('global.expression')}
        />
        <Select
          onChange={value => {
            setFacialHair(value);
          }}
          className="select"
          value={facialHair}
          options={presets
            ?.find(el => el.title === SelectorTitles.FACIAL_HAIR)
            ?.presetOptions.map(preset => ({ value: preset.id, label: preset.title }))}
          placeholder={t('global.facialHair')}
        />
      </div>
      <div className="shaved-head-block">
        <p className="description">{t('generate.chooseNoMakeUp')}</p>
        <p className="generate__switch-block_title">Shaved head</p>
        <Switch
          uncheckedIcon={false}
          offColor={'#9F1500'}
          onColor="#878A00"
          className="react-switch-bg"
          checkedIcon={false}
          onChange={() => setShavedHeadToggle(prev => !prev)}
          checked={shavedHeadToggle}
        />
      </div>
      <p className="bottom-description">{t('generate.clickTheThreeButtons')}</p>
    </div>
  );
};

export default Selectors;

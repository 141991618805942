import { useEffect } from 'react';

import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import api, { Queries } from 'api/axios';

interface Generations {
  id: string;
  images: [];
  status: string;
}

interface GenerateNewPrompt {
  details: string;
  activity: string;
  sourceImageId: string;
}

const useGenerateNewPrompt = (generationId: string) => {
  const { error, data, mutate, isPending } = useMutation<Generations, AxiosError, any>({
    mutationFn: (body: GenerateNewPrompt) =>
      api
        .post<{ result: Generations }>(`${Queries.GENERATIONS}/${generationId}/generations`, body)
        .then(res => res.data.result),
  });

  const generate = (imgId: string, clothing: string, activity: string) => {
    mutate({
      clothing: clothing,
      activity: activity,
      sourceImageId: imgId,
    });
  };

  useEffect(() => {
    if (error) {
      console.log(`Error: useSendResetInstructions - ${error.message}`);
    }
  }, [error]);

  return { error, data, generate, isPending };
};

export default useGenerateNewPrompt;

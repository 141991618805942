import { FC } from 'react';

interface Props {
  className?: string;
}

const CheckIcon: FC<Props> = ({ className }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="24" height="24" rx="12" fill="#4CAF50" />
    <path
      d="M7.33301 12.1588L10.8732 15.3334L16.6663 8.66669"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;

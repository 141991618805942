import { Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PresetOption as IPresetOption, PresetTypes } from 'api/models/presets';
import useGenerationPresets from 'api/queries/useGenerationPresets';
import CloseIcon from 'assets/svg/close-icon';
import PresetOption from 'components/PresetOption';
import PrimaryButton from 'components/PrimaryButton';
import { RSelectOption } from 'components/Select';
import { PhysicalDetailsContext } from 'context/PhysicalDetailsContext';

import SelectModal from './components/SelectModal';
import Selectors from './components/Selectors';
import './index.scss';

interface Props {
  onClose: () => void;
  isActive: boolean;
}

enum Presets {
  EYE = 'Eye color',
  HAIR = 'Hair color',
  SKIN_TONE = 'Skin tone',
}

type UpdatePreset = {
  updateId: (value: RSelectOption | undefined) => void;
  id: RSelectOption | undefined;
};

const PhysicalDetails: FC<Props> = ({ onClose, isActive }) => {
  const { t } = useTranslation();
  const { data } = useGenerationPresets();
  const { setHairColor, setEyesColor, setSkinTone, eyesColor, hairColor, skinTone, setIsVisited } =
    useContext(PhysicalDetailsContext);
  const skinTonePresets = useMemo<IPresetOption[] | undefined>(() => {
    if (!data) return;
    return data[PresetTypes.PHYSICAL_DETAILS]?.slice(0, 3)?.[0].presetOptions;
  }, [data]);
  const hairColorPresets = useMemo<IPresetOption[] | undefined>(() => {
    if (!data) return;
    return data[PresetTypes.PHYSICAL_DETAILS]?.slice(0, 3)?.[1].presetOptions;
  }, [data]);
  const eyeColorPresets = useMemo<IPresetOption[] | undefined>(() => {
    if (!data) return;
    return data[PresetTypes.PHYSICAL_DETAILS]?.slice(0, 3)?.[2].presetOptions;
  }, [data]);

  const [showSkinToneModal, setShowSkinToneModal] = useState(false);
  const [showHairColorModal, setShowHairColorModal] = useState(false);
  const [showEyeColorModal, setShowEyeColorModal] = useState(false);
  const [isClose, setIsClose] = useState(false);

  const setPresets: Record<string, UpdatePreset> = {
    [Presets.EYE]: { updateId: setEyesColor, id: eyesColor },
    [Presets.HAIR]: { updateId: setHairColor, id: hairColor },
    [Presets.SKIN_TONE]: { updateId: setSkinTone, id: skinTone },
  };

  const handleClose = () => {
    onClose();
    setIsClose(true);
  };

  useEffect(() => {
    if (isActive) {
      setIsVisited(true);
    } else {
      setIsClose(false);
    }
  }, [isActive]);

  return (
    <div
      className={`first-time-generation__physical-details-container ${isActive && !isClose ? 'active' : isActive && isClose ? 'hide' : ''}`}
    >
      <div className="presets-container">
        <div className="first-time-generation__physical-details-preset">
          <button className="select-button" onClick={() => setShowSkinToneModal(true)}>
            {t('Skin tone')}
          </button>
          {skinTone && (
            <PresetOption
              src={setPresets[Presets.SKIN_TONE].id?.preview!}
              title={setPresets[Presets.SKIN_TONE].id?.label!}
            />
          )}
        </div>
        <div className="first-time-generation__physical-details-preset">
          <button className="select-button" onClick={() => setShowHairColorModal(true)}>
            {t('Hair color')}
          </button>
          {hairColor && (
            <PresetOption src={setPresets[Presets.HAIR].id?.preview!} title={setPresets[Presets.HAIR].id?.label!} />
          )}
        </div>
        <div className="first-time-generation__physical-details-preset">
          <button className="select-button" onClick={() => setShowEyeColorModal(true)}>
            {t('Eye color')}
          </button>
          {eyesColor && (
            <PresetOption src={setPresets[Presets.EYE].id?.preview!} title={setPresets[Presets.EYE].id?.label!} />
          )}
        </div>
      </div>
      <Selectors presets={data && data[PresetTypes.PHYSICAL_DETAILS]} />
      <button className="first-time-generation__physical-details-close-btn" onClick={handleClose}>
        <CloseIcon />
      </button>
      {skinTonePresets && (
        <SelectModal
          title="Skin tone"
          options={skinTonePresets}
          show={showSkinToneModal}
          columns={2}
          onClose={() => setShowSkinToneModal(false)}
          onSelect={value =>
            setPresets[Presets.SKIN_TONE].updateId({ value: value.id, label: value.title, preview: value.previewUrl! })
          }
        />
      )}
      {hairColorPresets && (
        <SelectModal
          title="Hair color"
          options={hairColorPresets}
          show={showHairColorModal}
          columns={3}
          onClose={() => setShowHairColorModal(false)}
          onSelect={value =>
            setPresets[Presets.HAIR].updateId({ value: value.id, label: value.title, preview: value.previewUrl! })
          }
        />
      )}
      {eyeColorPresets && (
        <SelectModal
          title="Eye color"
          options={eyeColorPresets}
          show={showEyeColorModal}
          columns={3}
          onClose={() => setShowEyeColorModal(false)}
          onSelect={value =>
            setPresets[Presets.EYE].updateId({ value: value.id, label: value.title, preview: value.previewUrl! })
          }
        />
      )}
    </div>
  );
};

export default PhysicalDetails;

import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import PrimaryButton from 'components/PrimaryButton';

import './index.scss';

export type MyParams = {
  token: string;
};

interface Props {
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const Footer: FC<Props> = ({ setVisible }) => {
  const { t } = useTranslation();
  return (
    <div className="log-in__button-container">
      <PrimaryButton onClick={() => setVisible(true)} title={t('signIn.login')} />
    </div>
  );
};

export default Footer;

import { CSSProperties, FC } from 'react';

import css from './index.module.scss';

import { DownloadIcon } from '../../assets/svg/download-icon';

interface Props {
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
}

export const DownloadButton: FC<Props> = ({ onClick, disabled, className, style }) => (
  <button className={`${css.download_button_default} ${className}`} disabled={disabled} style={style} onClick={onClick}>
    <DownloadIcon />
  </button>
);

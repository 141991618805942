import { createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useMemo, useState } from 'react';

import { RSelectOption } from 'components/Select';
import { LightingSelectorTitles } from 'pages/generation/components/Lighting/components/Selectors';

interface Context {
  shot: RSelectOption | undefined;
  setShot: Dispatch<SetStateAction<RSelectOption | undefined>>;
  lightingType: RSelectOption | undefined;
  setLightingType: Dispatch<SetStateAction<RSelectOption | undefined>>;
  lighting: RSelectOption | undefined;
  setLighting: Dispatch<SetStateAction<RSelectOption | undefined>>;
  isLightingReady: boolean;
  isVisited: boolean;
  setIsVisited: Dispatch<SetStateAction<boolean>>;
  resetAllStates: () => void;
  currentIds: string[];
  cameraType: RSelectOption | undefined;
  setCameraType: Dispatch<SetStateAction<RSelectOption | undefined>>;
}

export const LightingContext = createContext<Context>({} as Context);

interface Props {
  children: ReactNode;
}

export const LightingProvider: FC<Props> = ({ children }) => {
  const [cameraType, setCameraType] = useState<RSelectOption>();
  const [shot, setShot] = useState<RSelectOption>();
  const [lighting, setLighting] = useState<RSelectOption>();
  const [lightingType, setLightingType] = useState<RSelectOption>();
  const [currentIds, setCurrentIds] = useState<string[]>([]);

  const [isVisited, setIsVisited] = useState(false);

  useEffect(() => {
    const ids: string[] = [];
    shot?.value && setCurrentIds([...ids, shot.value]);
    cameraType?.value && setCurrentIds([...ids, cameraType.value]);
    lightingType?.value && setCurrentIds([...ids, lightingType.value]);
    setCurrentIds(ids);
  }, [shot, lightingType, cameraType]);

  const isLightingReady = useMemo(() => {
    if (lightingType && lightingType.label === LightingSelectorTitles.BLACK_AND_WHITE_SETTINGS) {
      return !!(shot && lighting && lightingType);
    } else {
      return !!(shot && lighting && lightingType && cameraType);
    }
  }, [shot, lighting, lightingType, cameraType]);

  const resetAllStates = () => {
    setCameraType(undefined);
    setShot(undefined);
    setLighting(undefined);
    setLightingType(undefined);
    setIsVisited(false);
  };

  return (
    <LightingContext.Provider
      value={{
        isVisited,
        setIsVisited,
        currentIds,
        lighting,
        setLighting,
        lightingType,
        setLightingType,
        shot,
        setShot,
        isLightingReady,
        resetAllStates,
        cameraType,
        setCameraType,
      }}
    >
      {children}
    </LightingContext.Provider>
  );
};
